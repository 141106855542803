// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notfound {
    position: relative;
    z-index: 10;

    overflow: hidden;

    width: 100vw;
    height: 100vh;

    background-color: #212120;
}
`, "",{"version":3,"sources":["webpack://./src/components/Page/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;;IAEX,gBAAgB;;IAEhB,YAAY;IACZ,aAAa;;IAEb,yBAAyB;AAC7B","sourcesContent":[".notfound {\n    position: relative;\n    z-index: 10;\n\n    overflow: hidden;\n\n    width: 100vw;\n    height: 100vh;\n\n    background-color: #212120;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
