import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import List from '../Order/History/List/List';
import { Input } from '../FormComponents';
import { currencyFormatter, mainStore } from '../../stores/MainStore';
import './LotsView.css';
import MTReportViewer from '../Order/Details/MTReportViewer';
import LoadingSipnner from '../UI/LoadingSipnner';
import { UNITS } from '../Order/OrderDetails/Pages/NetSuiteStatusValues';
import { toJS } from 'mobx';

class LotsView extends Component {
    constructor(props){
        super(props)

        this.state = {
            filteredData: [],
            isLoading: true,
            mtr_id: null,
            mtr_filename: null,
            mtr_id_rc: null,
            mtr_filename_rc: null,
            inventorynumber: null,
            showViewer: false,
            selectedLots: [],
            selectedLotNumbers: [],
            columns: [
                {
                    id: 'inventorynumber',
                    accessorKey: 'inventorynumber',
                    cell: info =>  info.getValue(),
                    header: () => 'Lot Number',
                    footer: info => info.column.id
                },
                {
                    id: 'heat_number',
                    accessorKey: 'heat_number',
                    cell: info =>  info.getValue(),
                    header: () => 'Heat',
                    footer: info => info.column.id
                },
                {
                    id: 'rate',
                    accessorKey: 'rate',
                    cell: info => info.getValue(),
                    header: () => 'Delivered Rate',
                    footer: info => info.column.id
                },
                {
                    id: 'quantityavailable',
                    accessorKey: 'quantityavailable',
                    cell: info =>  info.getValue(),
                    header: () => 'Qty Available',
                    footer: info => info.column.id
                }
            ],
            isDownloading: false
        };
        this.closePDFViewer = this.closePDFViewer.bind(this)
        this.addToCart = this.addToCart.bind(this)
    };

    UNSAFE_componentWillReceiveProps(){
        //The need to update filteredData with rate from Inventory Search Component
        //Notice that rate is already present in the application state before filteredData becomes a thing
        const { filteredData } = this.state;
        const { rate } = this.props;

        filteredData.forEach((element) => {
            element.rate = parseFloat(rate).toFixed(4);
            element.upfrontPrice = element.quantityavailable ? currencyFormatter(parseFloat(rate * element.quantityavailable).toFixed(2),'USD','en-US') : "";
        });
    }

    componentDidUpdate(){
        const { cartItems, action } = this.props.inventoryStore;
        const { selectedLots } = this.state;
        if(selectedLots.length > cartItems.length && action === 'remove') {
            let lotNumbers = cartItems.map((element) => element.inventorynumber);
            this.setState({ selectedLots: lotNumbers });
        }
    }

    componentDidMount(){
        this.props.itemId && this.props.itemNSId && this.props.locationId && toJS(this.props.inventoryStore.fetchLotsByItem(this.props.itemId,this.props.itemNSId,this.props.locationId))
        .then(() => {
            const { lotData, cartItems } = this.props.inventoryStore;
            let cart = [];
            cartItems.forEach((element) => {
                cart.push(element.inventorynumber)
            });

            lotData.forEach((element) => {
                element.mtr = element.mtr_id  && element.mtr_filename ? <div className="download-mtr">
                    {/* Some PDFs DO NOT Render properly. AAM-1152 has been created for this reason */}
                    <div className="view-report" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}} onClick={() => this.handleMTRView(element.mtr_id,element.mtr_filename,element.inventorynumber)}>View MTR</div>
                    -
                    <div className="view-report__inline" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}} onClick={() => this.handleMTRFile({ internalId: element.mtr_id, fileName: element.mtr_filename})}>Download MTR</div>
                </div>: <div></div>;

                element.price = this.props.itemPrice
                element.warehouseLocationId = this.props.warehouseLocationId;
                element.rate = parseFloat(this.props.rate).toFixed(4);
                element.upfrontPrice =  currencyFormatter(parseFloat(this.props.rate * element.quantityavailable).toFixed(4),'USD','en-US');
                element.lot_eta = element.status === "In Stock" && element.in_stock_date ? moment(element.in_stock_date).local().format("YYYY-MM-DD") : moment(element.eta_to_dest).local().format("YYYY-MM-DD");
            })
            let processedLotData= lotData.sort((a,b)=> (a.status.localeCompare(b.status)  || a.lot_eta.localeCompare(b.lot_eta)  ));

            this.setState({
                // toJS needs to be used when passing some MobX objects to react state
                filteredData: toJS(processedLotData) || [],
                selectedLots: toJS(cart),
                columns: [
                    {
                        id: 'inventorynumber',
                        accessorKey: 'inventorynumber',
                        cell: info => info.getValue(),
                        header: () => 'Lot Number',
                        footer: info => info.column.id,
                        size: 45
                    },
                    {
                        id: 'heat_number',
                        accessorKey: 'heat_number',
                        cell: info => info.getValue(),
                        header: () => 'Heat',
                        footer: info => info.column.id,
                        size: 40
                    },
                    {
                        id: 'rate',
                        accessorKey: 'rate',
                        cell: info => info.getValue(),
                        header: () => 'Delivered Rate',
                        footer: info => info.column.id,
                        size: 50
                    },
                    {
                        id: 'quantityavailable',
                        accessorKey: 'quantityavailable',
                        cell: info => info.getValue(),
                        header: () => 'Qty Available',
                        footer: info => info.column.id,
                        size: 50
                    },
                    {
                        id: 'stockunit',
                        accessorKey: 'stockunit',
                        cell: info => UNITS[info.getValue()] || '',
                        header: () => 'UOM',
                        footer: info => info.column.id,
                        size: 30
                    },
                    {
                        id: 'piece_count',
                        accessorKey: 'piece_count',
                        cell: info => info.getValue(),
                        header: () => 'Pieces',
                        footer: info => info.column.id,
                        size: 30
                    },
                    {
                        id: 'status',
                        accessorKey: 'status',
                        cell: info => info.getValue(),
                        header: () => 'Status',
                        footer: info => info.column.id,
                        size: 30
                    },
                    {
                        id: 'lot_eta',
                        accessorKey: 'lot_eta',
                        cell: info => info.getValue(),
                        header: () => 'ETA to Warehouse',
                        footer: info => info.column.id,
                        size: 60
                    },
                    {
                        id: 'location',
                        accessorKey: 'location',
                        cell: info => info.getValue(),
                        header: () => 'Location',
                        footer: info => info.column.id,
                        size: 40
                    },
                    {
                        id: 'mtr',
                        accessorKey: 'mtr',
                        cell: info => info.getValue(),
                        header: () => 'MTR',
                        footer: info => info.column.id,
                        size: 80
                    }
                ],
                isLoading: false
            });
        })
    }

    closeViewer(){
        this.props.inventoryStore.toggleLotsView(false);
    }
    closePDFViewer() {
        this.setState({ showViewer: false })
    }

    handleMTRFile(fileParams) {
        this.setState({ isDownloading: true });
        this.props.orderStore.fetchMTRDownload(fileParams)
        .then(() => {
            this.setState({ isDownloading: false })
        });
    }

    // Currently this handler is not in use, needs to be fixed
    handleMTRView(mtr_id, mtr_filename ,inventorynumber) {
        this.setState({ mtr_id, mtr_filename, inventorynumber, showViewer: true });
    }

    // To do: This should really just add a class or something minimal to the purchase-order-board
    // Styles themselves should be in the style sheet, not here
    openCartPanel() {
        const element = document.getElementById("purchase-order-board");
        element.style.right = "0";
        element.style.transition = "0.5s ease-in-out";
    }

    closeCartPanel(){
        const element = document.getElementById("purchase-order-board")
        element.style.right = "-1000px";
        element.style.transition = "0.5s ease-in-out";
    }

    async addToCart(e, lot_id){
        let checkedLots = [];

        //Get selected lots from state, Get cart Items
        const { selectedLots } = this.state;

        const { cartItems, action } = this.props.inventoryStore;

        if(action === 'remove') {
            this.props.inventoryStore.setAction('add');
        }

        if (e.target.checked) {
            //unpack already selected lots and add the new lot value
            checkedLots = [...selectedLots, lot_id];

            //update state with new list of selected lots
            this.setState({ selectedLots: checkedLots });

            const { filteredData } = this.state;

            const { description } = this.props;
            const { currentCompanyID } = this.props.authStore;

            let selectedLot = filteredData.find((element) => element.inventorynumber === lot_id);
            selectedLot.description = description;
            selectedLot.price = this.props.itemPrice;

            //remove mtr key/value before storing cart item in Order Cloud
            delete selectedLot['mtr'];
            // Construct Order Cloud Cart Line Items
            let ocCartLinePayLoad = { ProductID: selectedLot.ocItemID, Quantity: 1, InventoryRecordID: selectedLot.ocLotID, CompanyID: currentCompanyID, InventoryRecord: JSON.stringify(selectedLot) };
            await this.props.cartStore.saveCartLineItem(ocCartLinePayLoad)
            const { cartResponse } = this.props.cartStore;
            if(cartResponse) {
                selectedLot.ocCartLineItemID = cartResponse.ID;

                //open cart panel
                this.openCartPanel();

                //Set Cart in Local
                this.props.inventoryStore.setCartItems(selectedLot,'add');
            } else {
                mainStore.setError("An error occured so your cart was not captured into our database. Please remove the item and try again.");
            }
        } else {
            //open cart panel
            this.openCartPanel();

            //remove unchecked lot
            checkedLots = selectedLots.filter((element) => element !== lot_id);

            //update state with checked lots
            this.setState({ selectedLots: checkedLots });

            //get unchecked lot from cart
            let uncheckedLot = cartItems.filter((element) => element.inventorynumber === lot_id)[0];

            //this.setState({ isSubmitting: true})
            if(uncheckedLot) {
                this.props.cartStore.removeCartLineItem(uncheckedLot.ocCartLineItemID)
                .then(() => {
                    //this.setState({ isSubmitting: false})

                    //updated cart items
                    const { cartItems } = this.props.inventoryStore;

                    //close the cart when there are no items in it.
                    //cart items length === 1 here means there are no items in the cart.
                    //state change delays
                    // TODO:: https://fjorge.atlassian.net/browse/AAM-1243
                    if(cartItems?.length === 1) {
                        this.closeCartPanel()
                    }
                    //Set Cart in Local
                    this.props.inventoryStore.unSetCartItem(lot_id,'remove');
                })
            }
        }
    }

    render(){
        const {
            filteredData,
            mtr_id,
            mtr_filename,
            inventorynumber,
            showViewer,
            selectedLots,
            columns,
            isLoading,
            isDownloading
        } = this.state;

        /** 
         * This line is to force a re-render when items are removed from the cart or checkout.
         * This will make the UI (add to My PO button) change from dark-red back to green
         * Do not comment it out
        **/
        const { cartItems } = this.props.inventoryStore;

        const { description } = this.props;

        let columnsData = isLoading ? columns :
            [
                {
                    id: 'select',
                    accessorKey: 'inventorynumber',
                    cell: info => filteredData.length > 0 ? <Input type={"customCheckbox"} checked={selectedLots.includes(info.getValue())} label={""} name={'Coil'} value={"Coil"} onChange={(event) => this.addToCart(event, info.getValue())} /> : null,
                    header: () => 'Select',
                    footer: info => info.column.id,
                    size: 50
                },
                ...columns
            ];

        return (
            <section className="box inventory lot">
                <div className='lot-view-close'>
                    <h3  onClick={ () => this.closeViewer() }>Back</h3>
                    <h3 id='lot-view-close-btn' onClick={ () => this.closeViewer() }>&times;</h3>
                </div>
                <div className="panel" id="order-items">
                    <List 
                        columns={columnsData} 
                        data={filteredData} 
                        listType="inventory" 
                        rowSize={filteredData.length} 
                        noDataText={"No available lots"}
                        description={`There are ${(filteredData.length) || 0} Lot(s) for the item: ${description}`}
                        isLoading={isLoading} 
                    />
                </div>
                { isDownloading && <LoadingSipnner /> }
                { isDownloading && <div style={{ position: 'absolute', top: '40%', right: '10%', left: '10%', color: "#283A8E", fontWeight: "500", zIndex: 9999, textAlign: "center"}}></div>}
                { showViewer && <MTReportViewer tranId={inventorynumber} mtr_id={mtr_id} mtr_filename={mtr_filename} type={'material-test-report'} back={this.closePDFViewer}/>}
            </section>
        )
    }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(LotsView));
