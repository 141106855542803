import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import TagManager from 'react-gtm-module'
import * as Sentry from "@sentry/react";
import { Session } from 'bc-react-session';

// Style
import './App.css';

// Page components
import TopNavNew from './components/Page/TopNav/TopNavNew';
import NotFound from './components/Page/NotFound/NotFound';
import PackageDownload from './components/PackageDownload/PackageDownload';

// Components
import LoginForm from './components/Login/Login';
import Home from './components/Home/Home';
import History from './components/Order/History/History';
import Details from './components/Order/Details/Details';
import PDFViewer from './components/Order/Details/PDFViewer';
import MTRPDFViewer from './components/Order/Details/MTRPDFViewer';
import OrderDetails from './components/Order/OrderDetails/OrderDetails';
import InventorySummary from './components/InventorySummary/InventorySummary';
import InventorySearch from './components/InventorySummary/InventorySearch';
import OpenQuotes from './components/OpenQuotes/OpenQuotes';
import AccountSettings from './components/AccountSettings/AccountSettings';
import Claims from './components/ClaimForm/Claims';
import { BOL, INV, LOT } from './components/Order/History/SearchViews';

import SalesOrder from './components/Order/OrderDetails/Pages/SalesOrder';

// UI components
import Errors from './components/UI/Errors';
import Success from './components/UI/Success';
import Info from './components/UI/Info';
// import VersionTag from './components/UI/VersionTag';
import CompanyInfo from './components/Home/CompanyInfo';
import FeedBackForm from './components/FeedBack/FeedBack';
import TopNavSkeleton from './components/Page/TopNav/TopNavSkeleton';
import { authStore } from './stores/AuthStore';
import RequestForQuote from './components/Order/OrderDetails/Pages/RequestForQuote';
import RelatedRecords from './components/Order/OrderDetails/Pages/RelatedRecords';
import Invoice from './components/Order/OrderDetails/Pages/Invoice';
import InvoiceDetails from './components/Order/OrderDetails/Pages/InvoiceDetails';
import ItemFulfillment from './components/Order/OrderDetails/Pages/ItemFulfillment';
import ItemFulfillmentDetails from './components/Order/OrderDetails/Pages/ItemFulfillmentDetails';
import BillOfLadingDetails from './components/Order/OrderDetails/Pages/BillOfLadingDetails';
import RecentIFBillOfLadingDetails from './components/Order/OrderDetails/Pages/RecentIFBillOfLadingDetails';

class App extends Component {

    componentDidMount(){
        window.onload = this.props.authStore.resetTimer;
        document.onmousemove = this.props.authStore.resetTimer;
        document.onkeydown = this.props.authStore.resetTimer;

        //get session date from local storage
        const loggedInStatus  = Session.get("aametals-app-session");

        if(!loggedInStatus.active) {
            this.fetchAnnouncements();
        }

        //initialize tag manager
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG_ID
        }
        TagManager.initialize(tagManagerArgs)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);

            // Log a virtual pageview event
            TagManager.dataLayer({
                dataLayer: {
                    event: 'virtualPageview',
                    pagePath: this.props.location.pathname
                }
            });
        }
    }

    handleContinueSession(){
        authStore.toggleLogoutModal();
        authStore.initTimeoutWorker();
        authStore.modalTimerValue = 0;
    }

    handleLogout(){
        authStore.logout();
        window.location.reload();
    }

    fetchAnnouncements() {
        authStore.fetchAnnouncements('Login','getShortMessage')
        .then(() => {})
    }

    render() {

        const { userCompanies } = this.props.authStore;
        //This ensures that the session timer does not attain negative values.
        if((authStore.timeoutWarning - authStore.modalTimerValue) <= 0) {
            this.handleLogout();
        }

        return (
            <main className="main">
                { authStore.logoutModalOpen && (
                    <div className="modal modal--force-logout">
                            <div className="modal__container">
                            <p className="force-logout__message">{`You will be logged out due to inactivity in ${(authStore.timeoutWarning - authStore.modalTimerValue) / 1000} seconds...`}</p>
                            <button className="button button--logout" onClick={this.handleLogout}>Logout</button>
                            <button className="button button--continue" onClick={this.handleContinueSession}>Continue Session</button>
                        </div>
                    </div>
                )}
                { userCompanies ? <TopNavNew /> : <TopNavSkeleton />}
                <Routes>
                    <Route path="/" element={ <LoginForm /> } />
                    <Route path="/login" element={ <LoginForm /> } />
                    <Route path="/home" element={ <Home /> } />
                    <Route path="/order/history" element={ <History /> } />
                    <Route path="/order/pdfviewer/:id/:type" element={ <Details /> } />
                    <Route path="/order/pdfviewer/:id/:internalId/:type" element={ <PDFViewer /> } />
                    <Route path="/order/mtrpdfviewer/:id/:internalId/:fileName/:type" element={ <MTRPDFViewer /> } />
                    <Route path="/order/detail/*" element={ <OrderDetails /> } >
                        <Route path="/order/detail/*/order/:id/:internalId/*" element={ <SalesOrder /> } />
                        <Route path="/order/detail/*/quote/:id/*" element={ <RequestForQuote/> } />
                        <Route path="/order/detail/*/relatedrecords/:id/*" element={ <RelatedRecords /> } />
                        <Route path="/order/detail/*/invoice/:id/:internalId/*" element={ <Invoice /> } />
                        <Route path="/order/detail/*/invoice-details/:id/:INV_ID/*" element={ <InvoiceDetails /> } />
                        <Route path="/order/detail/*/itemfulfillment/:id/:internalId/*" element={ <ItemFulfillment /> } />
                        <Route path="/order/detail/*/itemfulfillment-details/:id/:IF_ID/:truckIds/*" element={ <ItemFulfillmentDetails /> } />
                        <Route path="/order/detail/*/itemfulfillment-bol/:id/*" element={ <BillOfLadingDetails /> } />
                    </Route>
                    <Route path="/invoice-search" element={ <INV /> } />
                    <Route path="/bol-search" element={ <BOL /> } />
                    <Route path="/lot-search" element={ <LOT /> } />
                    <Route path="/claims" element={ <Claims /> } />
                    <Route path="/open-quotes" element={ <OpenQuotes /> } />
                    <Route path="/inventory-summary" element={ <InventorySummary /> } />
                    <Route path="/inventory-search" element={ <InventorySearch /> } />
                    <Route path="/company-info" element={ <CompanyInfo /> } />
                    <Route path="/feedback" element={ <FeedBackForm /> } />
                    <Route path="/account-settings" element={ <AccountSettings /> } />
                    <Route path="/recent-itemfulfillment-bol" element={ <RecentIFBillOfLadingDetails /> } />
                    <Route path="/order/download/package/:packageHash" element={ <PackageDownload /> } />
                    <Route path="*" element={ <NotFound /> } />
                </Routes>

                <Errors />
                <Success />
                <Info />
                {/* <button type='button' className='purechat-button-expand' id='pure-chat-btn-home-page'>
                    <i style={{ fontSize: '1.2rem', fontWeight: 'bold' }} className="fas fa-comment-dots"></i>
                    Chat with us! 
                </button> */}
            </main>
        );
    }

}

export default Sentry.withProfiler(inject('authStore')(observer(App)));
