import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { toJS } from 'mobx';

import { Modal, Button } from 'antd';


// Style
import './Home.css';
import LMEView from '../LMEView/LMEView'
import RecentItemFulfillments from '../RecentItemFulfillments/RecentItemFulfillments';
import OrderHistory from '../Order/History/OrderHistory';
import { CardContainer, Card } from '../Page/Card/Card';
import { currencyFormatter } from '../../stores/MainStore'
import SkeletonLoader from '../UI/SkeletonLoader';
import Announcement from './Announcement/Announcement';

//Constants
import { formatTableData } from '../Order/OrderDetails/Pages/NetSuiteStatusValues';
import Loader from '../UI/Loader';
import TransparentLoader from '../UI/TransparentLoader';
import { authStore } from '../../stores/AuthStore';
// import Modal from '../UI/Modal';

class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            LMEColumns: [
                {
                    id: 'date',
                    accessorKey: 'date',
                    cell: info => info.getValue(),
                    header: () => 'Date',
                    footer: info => info.column.id
                },
                {
                    id: 'month',
                    accessorKey: 'month',
                    cell: info => info.getValue(),
                    header: () => 'Month',
                    footer: info => info.column.id
                },
                {
                    id: 'year',
                    accessorKey: 'year',
                    cell: info => info.getValue(),
                    header: () => 'Year',
                    footer: info => info.column.id
                },
                {
                    id: 'cashlme',
                    accessorKey: 'cashlme',
                    cell: info => info.getValue(),
                    header: () => 'Cash LME',
                    footer: info => info.column.id
                },
                {
                    id: 'monthlm',
                    accessorKey: 'monthlm',
                    cell: info => info.getValue(),
                    header: () => '3 Month LME',
                    footer: info => info.column.id
                },
                {
                    id: 'premium',
                    accessorKey: 'premium',
                    cell: info => info.getValue(),
                    header: () => 'Midwest Premium',
                    footer: info => info.column.id
                }
            ],
            LMEData: undefined,
            isLoadingLME: true,
            itemFulfillmentsColumns: [
                {
                    id: 'salesorder',
                    accessorKey: 'salesorder',
                    cell: info => info.getValue(),
                    style: { 'whiteSpace': 'unset' },
                    header: () => 'Sales Order',
                    footer: info => info.column.id
                },
                {
                    id: 'otherrefnum',
                    accessorKey: 'otherrefnum',
                    cell: info => info.getValue(),
                    style: { 'whiteSpace': 'unset' },
                    width: 150,
                    header: () => 'My PO',
                    footer: info => info.column.id
                },
                {
                    id: 'tranid',
                    accessorKey: 'tranid',
                    cell: info => info.getValue(),
                    header: () => 'IF #',
                    footer: info => info.column.id
                },
                {
                    id: 'trandate',
                    accessorKey: 'trandate',
                    cell: info => info.getValue(),
                    header: () => 'Date',
                    footer: info => info.column.id
                }
            ],
            isLoadingItemFulfillments: true,
            itemFulfillmentsData: [],
            recentSalesOrdersColumns: [
                {
                    id: 'tranid',
                    accessorKey: 'tranid',
                    cell: info => info.getValue(),
                    style: { 'whiteSpace': 'unset' },
                    header: () => 'Sales Order Id',
                    footer: info => info.column.id
                },
                {
                    id: 'otherrefnum',
                    accessorKey: 'otherrefnum',
                    cell: info => info.getValue(),
                    style: { 'whiteSpace': 'unset' },
                    header: () => 'My PO',
                    footer: info => info.column.id
                },
                {
                    id: 'statusName',
                    accessorKey: 'statusName',
                    cell: info => info.getValue(),
                    header: () => 'Status',
                    footer: info => info.column.id
                },
                {
                    id: 'createddate',
                    accessorKey: 'createddate',
                    cell: info => info.getValue(),
                    header: () => 'Created Date',
                    footer: info => info.column.id
                },
                {
                    id: 'dueDate',
                    accessorKey: 'dueDate',
                    cell: info => info.getValue(),
                    header: () => 'Due Date',
                    footer: info => info.column.id
                },
                {
                    id: 'custbodycustomer_order_method',
                    accessorKey: 'custbodycustomer_order_method',
                    cell: info => info.getValue() && formatTableData('orderMethod', info.getValue()),
                    header: () => 'Order Method',
                    footer: info => info.column.id
                },
                {
                    id: 'custbody6',
                    accessorKey: 'custbody6',
                    cell: info => info.getValue() && formatTableData('orderCondition', info.getValue()),
                    header: () => 'Condition',
                    footer: info => info.column.id
                }
            ],
            recentSalesOrdersData: [],
            isLoadingRecentOrders: true,
            isLoadingAnnouncements: true,
            currentCompanyID: "",
            customer: null,
            contact: null,
            message: "",
            isLoading: false,
            showModal: true   //this.props.authStore.showModal
        };

        this.fetchItemFulfillmentsData = this.fetchItemFulfillmentsData.bind(this);
        this.closeModal = this.closeModal.bind(this)
    }

    async componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {

            this.setState({ isLoading: true, message: "Loading user companies and delivery rates. Please wait " })

            await this.props.inventoryStore.getDeliveryRates();

            //fetch logged in user's assigned companies (userCompanies) in NetSuite using user email
            await this.props.authStore.fetchUserCompanies(this.props.authStore.user.email)

            //get the userCompanies after the server request is done
            const { userCompanies, currentCompanyID } = toJS(this.props.authStore)
            if(!userCompanies) {
                authStore.logout();
                window.location.reload()
                return;
            }
            //conditionally set companyId variable: 
            const companyId = currentCompanyID ? currentCompanyID : userCompanies[0].companyId

            //if user has companies (userCompanies) configured in NetSuite, fetch user details
            if(userCompanies && userCompanies.length > 0){
                
                this.setState({ message: "Loading customer data. Please wait " })
                
                await this.props.authStore.fetchCustomer('customer', companyId)
                const { customer } = this.props.authStore
                this.setState({ customer: customer, contact: '' })
                this.fetchLMEData(companyId);
            }

            await this.props.authStore.fetchCustomerShippingAddress(companyId)
            
            this.fetchAnnouncements('Dashboard')
        } else {
            window.location.href = '/login';
        }
    }

    fetchLMEData(companyId){

        if(this.props.inventoryStore.filteredMonthlyLME.length > 0) {
            let columns = [
                ...this.state.itemFulfillmentsColumns
            ];
            this.setState({ 
                LMEData: this.props.inventoryStore.filteredMonthlyLME, 
                itemFulfillmentsData: this.props.inventoryStore.recentItemFulfillments, 
                itemFulfillmentsColumns: columns 
            });
        }
        this.props.inventoryStore.fetchLME()
        .then(() => {
            const { filteredMonthlyLME } = this.props.inventoryStore;
            this.setState({ LMEData: filteredMonthlyLME, isLoadingLME: false });
            this.fetchItemFulfillmentsData(companyId);
        })
    }

    fetchItemFulfillmentsData(companyId){

        this.setState({ message: "Loading recent item fulfilments. Please wait " })

        this.props.inventoryStore.fetchRecentItemFulfillments(companyId)
        .then(() => {
            const { recentItemFulfillments } = this.props.inventoryStore;
            let columns = [
                ...this.state.itemFulfillmentsColumns,
                {
                    id: 'bol',
                    accessorKey: 'bol',
                    cell: info => <div className="related-records-table-cell"><div onClick={ () => this.viewBOLDetails(`/recent-itemfulfillment-bol`,info.getValue()) }>{ info.getValue() ? "View BOL" : ""}</div></div>,
                    width: 150,
                    header: () => 'Bill Of Lading',
                    footer: info => info.column.id
                }
            ];

            if(columns.length > 6) {
                columns.pop();
            }
            this.setState({ 
                isLoadingItemFulfillments: false, 
                itemFulfillmentsData: recentItemFulfillments, 
                itemFulfillmentsColumns: columns, 
                currentCompanyID: companyId 
            });

            this.fetchRecentSalesOrdersData(companyId);
        })
    }

    fetchRecentSalesOrdersData(companyId){

        this.setState({ message: "Loading recent sales orders. Please wait " })

        this.props.orderStore.fetchRecentOrders(companyId)
        .then(() => {
            const { recentOrders } = this.props.orderStore;
            let columns = [
                ...this.state.recentSalesOrdersColumns
                // { Header: 'Bill Of Lading', accessor: 'bol',width: 150, Cell: (props) => <div className="related-records-table-cell"><div onClick={ () => this.viewBOLDetails(`/order/detail/recent-itemfulfillment-bol`,props.value) }>{ props.value ? "View BOL" : ""}</div></div>}
            ];

            this.setState({ 
                isLoadingRecentOrders: false, 
                recentSalesOrdersData: recentOrders, 
                recentSalesOrdersColumns: columns,
                message: "",
                isLoading: false 
            });
            this.props.authStore.setHomepageAlreadyLoaded(true)
        })
    }

    fetchAnnouncements() {
        this.props.authStore.fetchAnnouncements('Dashboard','getShortMessage')
        .then(() => {
            this.setState({ isLoadingAnnouncements: false })
        })
    }

    viewBOLDetails(path,tranId){
        this.props.orderStore.setCurrentBillOfLading(tranId);
        this.props.history(path);
    }

    handleChange = (e) => {
        this.setState({...this.state, [e.target.name]: e.target.value});
    }

    closeModal(){
        this.setState({ showModal: false })
        // this.props.authStore.setHideNotice(true, false)
        this.props.authStore.setNoticeAlreadyLoaded(true)
    }

    render() {
        //state variables
        const {
            customer,
            LMEData, 
            isLoadingLME, 
            isLoadingRecentOrders,
            isLoadingAnnouncements, 
            isLoadingItemFulfillments, 
            LMEColumns, 
            itemFulfillmentsColumns, 
            recentSalesOrdersColumns, 
            pageIsLoading,
            isLoading,
            message,
            showModal 
        } = this.state

        //values from store
        const { viewDashboardKPI } = this.props.mainStore
        const { userCompanies, announcements, homepageAlreadyLoaded, noticeAlreadyLoaded } = this.props.authStore
        const { recentItemFulfillments } = this.props.inventoryStore;
        const { recentOrders } = this.props.orderStore

        if(pageIsLoading){
            return <Loader />
        }

        if(!customer){
            if(userCompanies && userCompanies.length < 1){
                return <section className="box home">
                    <div className="panel center">
                        <hgroup className="home__title">
                            <h1>Welcome to AA Metals!</h1>
                            <h3>
                                {
                                    // `The permissions for your user may have changed. Please contact ${salesRepDetails.name} for assistance. Email: ${salesRepDetails.email}. Phone: ${salesRepDetails.phone}`
                                    `We tried to load your data but it looks like the permissions for your user may have changed. Please contact your AA Metals representative for assistance regarding login access to the AA Metals Customer Portal.`
                                }
                            </h3>
                        </hgroup>
                    </div>
                </section>;
            }
        }
        let balance = currencyFormatter(customer && customer.balance,'USD','en-US');
        let overdueBalance = currencyFormatter(customer && customer.overdueBalance,'USD','en-US');
        let depositBalance = currencyFormatter(customer && customer.depositBalance,'USD','en-US');
        let creditLimit = currencyFormatter(customer && customer.creditLimit,'USD','en-US');
        return (
            <section className="box home">
                { viewDashboardKPI === "true" ? (!customer ? <SkeletonLoader /> : <h2>FINANCIALS</h2>) : null }
                {
                    viewDashboardKPI === "true" ? (!customer && <CardContainer>
                                    <SkeletonLoader height={55}/>
                                    <SkeletonLoader height={55}/>
                                    <SkeletonLoader height={55}/>
                                    <SkeletonLoader height={55}/>
                                </CardContainer>) : null
                }
                {
                    viewDashboardKPI === "true" ? (customer && <CardContainer>
                                    <Card text={"Balance"} value={balance} icon={"far fa-credit-card"}/>
                                    <Card text={"Overdue Balance"} value={overdueBalance} icon={"fas fa-money-bill"}/>
                                    <Card text={"Deposit Balance"} value={depositBalance} icon={"fas fa-money-bill"}/>
                                    <Card text={"Credit Limit"} value={creditLimit} icon={"fas fa-credit-card"}/>
                                </CardContainer>) : null
                }
                
                {/* Announcement */}
                {isLoadingAnnouncements ? <SkeletonLoader height={55}/> : <Announcement announcements={ (announcements && announcements.shortMessages) || [] } paddingTop={20} marginBottom={10}/> }

                <div className='dashboard-items'>
                    <div className='dashboard-item'>
                    { 
                        (!userCompanies || !customer) ? 
                        <SkeletonLoader height={105}/> :  
                        <RecentItemFulfillments 
                            columns={itemFulfillmentsColumns} 
                            data={recentItemFulfillments || []}
                            isLoading={isLoadingItemFulfillments} 
                        /> 
                    }
                    </div>
                    <div className='dashboard-item'>
                        { (!userCompanies || !customer) ? <SkeletonLoader height={105} /> :
                            <section className='box'>
                                <div className='panel dashboard-image'>
                                    <figure className="gallery__item gallery__item--1">
                                        <img src="./images/group.png" className="gallery__img" alt="Meeting" srcSet="" />
                                    </figure>
                                    <figure className="gallery__item gallery__item--2">
                                        <img src="./images/factory.png" className="gallery__img" alt="Factory" srcSet="" />
                                    </figure>
                                    <figure className="gallery__item gallery__item--3">
                                        <img src="./images/meeting.png" className="gallery__img" alt="Staff Members" srcSet="" />
                                    </figure>
                                    <img id='facility' src="./images/facility.png" className="gallery__img" alt="Metals" srcSet="" />
                                    <img id='product-1' src="./images/product-1.png" className="gallery__img" alt="Factory" srcSet="" />
                                    <img id='metals' src="./images/metals.png" className="gallery__img" alt="Metals" srcSet="" />
                                </div>
                            </section>
                        }
                    </div>
                    <div className='dashboard-item'>
                        <OrderHistory 
                            columns={recentSalesOrdersColumns}
                            data={recentOrders || []} 
                            isLoading={isLoadingRecentOrders}
                        /> 
                    </div>
                    <div className='dashboard-item'>
                        <LMEView 
                            columns={LMEColumns}
                            data={LMEData || []}
                            isLoading={isLoadingLME}  
                        />
                    </div>
                </div>
                { isLoading && !homepageAlreadyLoaded && <TransparentLoader message={message}/> }
                <Modal
                    title="Introducing Stainless Steel!"
                    open={showModal && !noticeAlreadyLoaded && announcements?.showNewFeatureNotice}
                    onCancel={this.closeModal}
                    footer={[
                        <Button key="back" type="primary" onClick={this.closeModal}>
                          Close
                        </Button>
                    ]}
                >
                    <span>AA Metals is excited to bring our customers another great series of products to complement aluminum sheets and coils.</span><br/><br/>
                    <span>To browse our extensive Stainless inventory, visit the <a href={`${window?.location?.origin}/inventory-search`}><b>Buy Metals</b></a> page and use the toggle in the top left corner to view a brand new line of metals.</span><br/><br/>
                    <span>The highest quality stainless steel alloys are now available from a variety of strategically located distribution facilities.</span>
                </Modal>
            </section>
        );
    }
}

export default inject('orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer((props) => <Home history={useNavigate()} {...props}/>));
