/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Session } from 'bc-react-session';

// Plugins
import moment from 'moment';

// Custom components
import { Overlay } from '../../Home/Info';

// Styles
import './TopNavNew.css';
import '../../AccountSettings/AccountSettings.css';
import LoadingSipnner from '../../UI/LoadingSipnner';
import PurchaseOrderBoard from '../../InventorySummary/PurchaseOrderBoard';
import PurchaseOrderForm from '../../InventorySummary/PurchaseOrderForm';
import PurchaseOrderConfirmation from "../../InventorySummary/PurchaseOrderConfirmation";
import TransparentLoader from '../../UI/TransparentLoader';

import { useNavigate } from 'react-router-dom';

export function withRouter(Children){
    return(props)=>{
       return <Children {...props}  history = {useNavigate()}/>
    }
}
class TopNavNew extends Component {
    constructor(props) {
        super(props);

        const { currentCompanyID, currentCompanyName } = this.props.authStore

        this.state = {
            currentCompany: currentCompanyName ? currentCompanyName : "You have no companies configured",
            currentCompanyID: currentCompanyID ? currentCompanyID : "",
            searchOpen: false,
            myAccountOpen: false,
            resetPurchaseOrderForm: false, //use this state variable to reset the checkout form
            showSaleRepWindow: false,
            switchingCompany: false,
            message: ""
        };

        this.toggleRef = React.createRef();
        this.showCart = this.showCart.bind(this)
        this.closeLotViewModal = this.closeLotViewModal.bind(this)
    }

    componentDidMount() {
        const session = Session.get("aametals-app-session");
        if (session && session.isValid) {
            //this.fetchData(this.state.currentCompanyID);
        }
    }

    fetchData = async (currentCompany) => {
        if(this.props.authStore.userCompanies){
            const { userCompanies } = this.props.authStore;
            this.props.authStore.clearContact();

            if(userCompanies && this.state.currentCompanyID !== "" && this.state.currentCompanyID !== "No Company Selected"){
                if((window.location.hash.split('/')).length === 3 && window.location.hash.split('/')[2] === "history"){
                    this.props.orderStore.fetchOrders({tranId:"", otherRefNum:"", status:"", tranDateBegin:moment().add(-1, 'months').format('YYYY-MM-DD'), tranDateEnd:moment().format('YYYY-MM-DD') });
                }
                this.props.authStore.fetchCustomer('customer', currentCompany?.companyId)
                .then(() => {
                    this.setState({ message: `Getting recent item fulfilments for ${currentCompany.companyName} company. Please wait ` })
                    this.props.inventoryStore.fetchRecentItemFulfillments(currentCompany?.companyId)
                    .then(() => {
                        this.setState({ message: `Getting recent orders for ${currentCompany.companyName} company. Please wait ` })
                        this.props.orderStore.fetchRecentOrders(currentCompany?.companyId)
                        .then(() => {
                            this.props.inventoryStore.setCheckCompanySwitch(currentCompany?.companyId)
                            this.setState({ resetPurchaseOrderForm: false, switchingCompany: false, message: "" })
                            this.props.history('/home');
                        })
                    })
                })

                await this.props.authStore.fetchCustomerShippingAddress(currentCompany?.companyId)
                const { customerDefaultShippingAddress } = this.props.authStore
                this.props.inventoryStore.setSelectedShipAddress(customerDefaultShippingAddress);
            }
        }
    }

    handleLogout = () => {
        this.props.authStore.logout();
        this.props.history('/');
        window.location.reload()
    }

    handleCompanySwitch = (e) => {
        const { value } = e.target;

        
        const { userCompanies } = this.props.authStore
        
        const currentCompany = userCompanies.find((item) => item.companyId === value);
        
        // Set current selected companyID in session for order history to use
        this.props.authStore.setCurrentCompany(currentCompany);

        this.props.inventoryStore.clearCart();
        this.props.authStore.resetAddresses();

        /**
         * TODO:: merge the styling toggling into on function
         */
        //Slide out/hide checkout form
        const checkout = document.getElementById("purchase-order-form")
        checkout.style.right = "-3000px";
        checkout.style.transition = "0.5s ease-in-out";

        //Slide out/hide cart
        const cart = document.getElementById("purchase-order-board")
        cart.style.right = "-1000px";
        cart.style.transition = "0.5s ease-in-out";

        this.setState({  
            currentCompanyID: value ? value : "", 
            currentCompany: currentCompany ? currentCompany.companyName : "No Company Selected", 
            resetPurchaseOrderForm: true, 
            switchingCompany: true, 
            message: `Switching company to ${currentCompany.companyName} company. Please wait `},

            () => this.fetchData(currentCompany)
        )
    }

    toggleSalesRep = () => {
        this.setState({ showSaleRepWindow: !this.state.showSaleRepWindow})
    }

    toggleSearch = (e) => {
        e.preventDefault();
        this.setState({ searchOpen: !this.state.searchOpen })
    }

    handleMyAccount = (e) => {
        e.preventDefault();
        this.setState({ myAccountOpen: !this.state.myAccountOpen });
    }

    handleSettings = (e) => {
        this.setState({ myAccountOpen: false })
    }

    handleSearch = (searchTerm) => {
        if(!searchTerm.bol && !searchTerm.lot && !searchTerm.inv && !searchTerm.heat){
            return;
        }

        let id = null
        let type = null
        for(let key in searchTerm){
            if(searchTerm[key] !== null){
                id = searchTerm[key]
                type = key
            }
        }

        this.props.orderStore.search(id,type);
    }

    showCart() {
        const { cartItems } = this.props.inventoryStore
        if(cartItems.length > 0) {
            const element = document.getElementById("purchase-order-board")
            element.style.right = 0;
            element.style.transition = "0.5s ease-in-out";
        }
    }

    closeLotViewModal() {
        this.props.inventoryStore.toggleLotsView(false)
    }

    render() {
        const { cartItems } = this.props.inventoryStore
        const { customer, userCompanies } = this.props.authStore;
        
        const { currentCompany, resetPurchaseOrderForm, showSaleRepWindow, switchingCompany, message } = this.state;

        let salesRepName = ""
        let salesRepEmail = ""
        let salesRepPhone = ""
        let salesRepOfficePhone = ""
        // let isSalesRepAssigned = false;
        let isSalesRepDataLoading = true;

        if(customer && customer.isSalesRepAssigned){
            let data = JSON.parse(customer.salesRepData)
            salesRepName = `${data.salesRepFirstname} ${data.salesRepLastname}`
            salesRepEmail = data.salesRepEmail
            salesRepPhone = data.salesRepPhone
            salesRepOfficePhone = data.salesRepOfficePhone
            // isSalesRepAssigned = true;
            isSalesRepDataLoading = false;
        }
        if(customer && customer.isSalesRepAssigned === false){
            isSalesRepDataLoading = false;
        }

        return (
            <header className="header-topnav">
                <Link to={ '/' } replace><img className="topnav-logo" src="/images/logo.png" alt="AA Metals" /></Link>
                {
                    <React.Fragment>
                        <nav>
                            <ul>
                                <li>
                                    <NavLink className="" to={ '/home' } data-cy="home">Home</NavLink>
                                </li>
                                {
                                    userCompanies &&
                                    userCompanies.length > 0 &&
                                    <React.Fragment>
                                        <li>
                                            <NavLink className="" to={ '/inventory-search' } data-cy="inventory-search" onClick={ this.closeLotViewModal }>Buy Metal</NavLink>
                                        </li>
                                        <li>
                                            { cartItems && userCompanies && userCompanies.length > 0 &&
                                                <a className="" name="button" data-cy="search"  type="button" onClick={ this.showCart }>
                                                    { cartItems.length > 0 && <span>{ (cartItems.map((element) => parseFloat(element.quantityavailable)).reduce((a,b) => a + b, 0)/1000).toFixed(2) }k LB</span> }
                                                    My New PO
                                                </a>
                                            }
                                        </li>
                                        <li>
                                            <a className="" name="button" data-cy="account" type="button">Search <i className='fa fa-caret-down'></i></a>
                                            <ul>
                                                <li>
                                                    <NavLink className="-"  data-cy="order-history"  to={ '/inventory-search' }><i className="fas fa-warehouse"></i>Search Inventory</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className=""  data-cy="my-orders"  to={ '/order/history' }><i className="fas fa-truck-moving"></i>Search My Orders</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className=""  data-cy="lot-search"  to={ '/lot-search' }><i className="fas fa-truck-moving"></i>Search My Lots/Heat</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className=""  data-cy="bol-search"  to={ '/bol-search' }><i className="fas fa-truck-moving"></i>Search My BOLs</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className=""  data-cy="invoice-search"  to={ '/invoice-search' }><i className="fas fa-file-invoice-dollar"></i>Search My Invoices</NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </React.Fragment>
                                }
                            </ul>
                            <ul>
                                <li>
                                    <a className="" name="button" data-cy="account" type="button">{ currentCompany } <i className='fa fa-caret-down'></i></a>
                                    <ul className="">
                                        {
                                            userCompanies &&
                                            userCompanies.length > 0 &&
                                            userCompanies.map((item,key) =>
                                                <li key={`usergroups-${key}`}>
                                                    <a className="" data-cy="order-history" onClick={ () => this.handleCompanySwitch({ target: { value: item.companyId } }) }>{ item.companyName }</a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </li>
                                <li>
                                    <a className="" name="button" data-cy="account" type="button">My Account <i className='fa fa-caret-down'></i></a>
                                    <ul className="">
                                        {
                                            userCompanies &&
                                            userCompanies.length > 0 &&
                                            <React.Fragment>
                                                <li>
                                                    <NavLink className=""  data-cy="order-history"  to={ '/order/history' } ><i className="fas fa-warehouse"></i>View Order History</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className=""  data-cy="company-info"  to={ '/company-info' }><i className="fas fa-building"></i>My Company Info</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className=""  data-cy="feedback"  to={ '/feedback' }><i className="fas fa-comment"></i>Send Feedback</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="" onClick={ this.handleSettings } data-cy="change-password"  to={ '/account-settings' }><i className="fas fa-key"></i>Change Password</NavLink>
                                                </li>
                                            </React.Fragment>
                                        }
                                        <li>
                                            <NavLink to="/" className="" onClick={ this.handleLogout } data-cy="logout"><i className="fas fa-sign-out-alt"></i>Logout</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                {
                                    userCompanies &&
                                    userCompanies.length > 0 &&
                                    <React.Fragment>
                                        <li>
                                            <a onClick={ this.toggleSalesRep }>My Sales Rep <i style={{ fontSize: '1rem', fontWeight: '600' }} className="fas fa-headset"></i></a>
                                            <div style={{ width: 300, position: 'absolute', top: -90, zIndex: 30, marginTop: 150, textAlign: "center" }}>
                                                {
                                                    showSaleRepWindow && <Overlay title="Sales Rep" close={this.toggleSalesRep}>
                                                                            {
                                                                                salesRepName ?
                                                                                <React.Fragment>
                                                                                    <div>Name: <span>{salesRepName}</span></div>
                                                                                    <div>Email: <span><a className="salesRepEmail" href={`mailto:${ salesRepEmail }`} >{ salesRepEmail }</a></span></div>
                                                                                    <div>Phone: <span>{salesRepPhone || salesRepOfficePhone}</span></div>
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment>
                                                                                  { isSalesRepDataLoading ?  <LoadingSipnner /> : "No Sales Rep Assigned" }
                                                                                </React.Fragment>
                                                                            }
                                                                    </Overlay>
                                                }
                                            </div>
                                        </li>
                                        {/* <li>
                                            <a type='button' className='purechat-button-expand' id='pure-chat-btn-home-page'>
                                                Chat with us! 
                                                <i style={{ marginLeft:'0.1rem', fontSize: '1rem', fontWeight: 'bold' }} className="fas fa-comment-dots"></i>
                                            </a>
                                        </li> */}
                                        {
                                            this.props.mainStore.show_staging_warning === "true" &&
                                            <li>
                                                <a id='warning'>NetSuite Sandbox</a>
                                            </li>
                                        }
                                    </React.Fragment>
                                }
                            </ul>
                        </nav>
                       {    userCompanies &&
                            userCompanies.length > 0 &&
                            <React.Fragment>
                                <PurchaseOrderBoard />
                                <PurchaseOrderForm resetForm={resetPurchaseOrderForm}/>
                                <PurchaseOrderConfirmation />
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                { switchingCompany && <TransparentLoader message={message} left={true}/> }
            </header>
        );
    }

}

export default inject('authStore','orderStore','mainStore', 'inventoryStore')(withRouter(observer(TopNavNew)));
