import axios from 'axios';

import { authStore } from '../stores/AuthStore';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
    (config) => {
        const configParams = JSON.parse(localStorage.getItem('authStore'))

        config.headers['Authorization'] = 'bearer ' + configParams?.token;
        config.headers['OrderCloudAuthorization'] = configParams?.ocToken;
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
);

api.interceptors.response.use(
    (response) => response,
      async (error) => {
        const originalRequest = { ...error.config, sent: false }; // Create a copy
        if (error.response?.status === 401 && !originalRequest.sent) {

          originalRequest.sent = true;
          //TODO :: AAM-1058
          // const newSession = await refreshToken(session?.token, session?.refreshToken);
          
          // Set the new token in the headers
          // if (newSession?.data?.accessToken && originalRequest?.headers) {
          //   originalRequest.headers['Authorization'] = `Bearer ${newSession?.data?.accessToken}`;
          // }
          //Temporary solution :: logout when token has expired
          authStore.logout();
          window.location.reload();
          
          return;
          // Make the retry request with the updated headers :: uncomment this when refresh token is implemented
          // return api(originalRequest);
        }

        return Promise.reject(error);
    }
);


export default api;