import { action, reaction, makeAutoObservable, observable, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import _ from 'loadsh';

// Plugins
import axios from 'axios';

import api from '../http';

// Stores
import { cartStore } from './CartStore';
import { mainStore, waitFor } from './MainStore';
import { authStore } from './AuthStore';
import { sendSentryData } from './Utils';

const FileDownload = require('react-file-download');

class InventoryStore {
    // Observable state/ store variables
    hydrated = false;
    token = undefined;
    ocToken = undefined;
    user = undefined;
    items = [];
    itemsFetched = [];
    item = undefined;
    itemLots = [];
    locations = [];
    lotStatus = [];
    itemsUpdatedAt = undefined;
    lotsUpdatedAt = undefined;
    lots = [];
    groups = [];
    filteredGroups = [];
    categories = [];
    parts = [];
    filter = undefined;
    contact = undefined;
    dailyLME = [];
    filteredDailyLME = [];
    monthlyLME = [];
    filteredMonthlyLME = [];
    allItems = []
    inventorySummary = undefined;
    filteredInventorySummary = undefined;

    inventorySearchData = undefined;
    itemGroups = undefined;
    itemLocations = undefined;
    itemGroup_ids = [];
    lotData = [];
    cartItems = [];
    cartTotal = 0;
    customerShippingAddress = [];
    addressList = [];
    showLotsView = false;
    isPendingRequest = false;
    checkCompanySwitch = "";
    action = "";
    deliveryRate = mainStore.deliveryRate;
    clearInventoryFilters = false;
    recentItemFulfillments = undefined;
    
    customerPriceLevel = undefined;
    shippingRates = undefined;
    openCheckout = false;
    selectedShipAddress = undefined;
    useAlternateAddress = false;
    deliveryRatesMatrix = []

    constructor() {
        makeAutoObservable(this, {
            customerPriceLevel: observable,
            shippingRates: observable,
            openCheckout: observable,
            selectedShipAddress: observable,
            customerShippingAddress: observable,
            cartItems: observable,
            useAlternateAddress: observable,
            deliveryRatesMatrix: observable,

            setShippingRates: action,
            openCheckoutPage: action,
            setSelectedShipAddress: action,
            setUseAlternateAddress: action,
            setCartItems: action,
            setDeliveryRates: action
        });
        makePersistable(this, { name: 'inventoryStore', properties: ['token', 'ocToken', 'user', 'customerPriceLevel','itemLocations', 'customerShippingAddress','shippingRates', 'selectedShipAddress', 'cartItems', 'deliveryRatesMatrix'], storage: window.localStorage });

        reaction(
            () => this.lots,
            (lots) => {
                if (lots) {
                    if (lots.length === 0 && this.user) {
                        this.fetchLots();
                    }
                }
            }
        );

        //Move intercepto codes to src/http TODO: unify axios interception : AAM-1198
        axios.interceptors.response.use(
            response => {
                if(response.headers.version === 'MisMatch') {
                    alert('A new version of the app is available. Click OK to get the new version. Thank you!')
                    authStore.logout()
                    window.location.reload()
                    return response;
                }
                return response
            },
            function (error) {
                if(error && error?.response?.status === 401) {
                    error.response.data.message ="Token has expired. You will be logged out shortly. Please login again after you have been logged out.";
                    window.localStorage.clear()
                    setTimeout(() => {
                        window.location.reload()
                    },10000)
                }
                return Promise.reject(error)
            }
        )
    }

    get itemsTotal() {
        return this.items.length;
    }

    get filteredGroupsNumber() {
        return this.filteredGroups.length;
    }

    get filteredGroupsTotal() {
        let total = 0;
        this.filteredGroups.forEach((el) => {
            total += el.items.length;
        });
        return total;
    }

    setHydrated(hydrated) {
        this.hydrated = hydrated;
    }

    setToken(token) {
        this.token = token;
    }
    setOCToken(ocToken) {
        this.ocToken = ocToken;
    }

    setUser(user) {
        this.user = user;
    }
    setInventorySummary(items){
        this.inventorySummary = items;
    }
    setfilteredInventorySummary(items){
        this.filteredInventorySummary = items;
    }
    //this a utility function that takes in a list of duplicate elements and returns a unique counterpart
    createUniqueList(data){
        let items = [];
        let set = new Set(data);
        set.forEach((set) => items.push(set))

        return items;
    }
    createUniqueItemGroup(data=[]){
        let uniqueGroup = data.filter((value,index) => {
            const _value = JSON.stringify(value);
            return index === data.findIndex(obj => {
                return JSON.stringify(obj) === _value
            })
        })

        return uniqueGroup;
    }

    setInventorySearchDataNew(data){
        if(!this.customerPriceLevel) {
            mainStore.setProgress({ loading: true });
            return;
        }
        const { items, deliveryRates } = data

        //Extract item properties for filtering in the UI
        const thickness = items.map((item) => item.ShipHeight);
        const width = items.map((item) => item.ShipWidth);
        const length = items.map((item) => item.ShipLength ? item.ShipLength : 0);

        //stainless filters
        const custitem_aam_steel_item_gauge = items.map((item) => {

            if(item?.xp?.netsuite?.filter === 'Stainless') {
                return item?.xp?.netsuite?.custitem_aam_steel_item_gauge
            }
            return null
        })

        const surface = items.map((item) => {
            if(item?.xp?.netsuite?.filter === 'Stainless') {
                return item?.xp?.netsuite?.custitem4
            }
            return null
        });

        //Sort length
        let parsedLength = this.createUniqueList(length);
        let sortedLength = parsedLength.sort(function (a, b) {  return a - b;  });

        //start preparing/formatting items for the buy metal page 
        let ItemsArray = [];
        items?.forEach((ele) => {

            ItemsArray.push({
                id: ele.ID,
                item: ele.Name,
                status: ele.status,
                itemGroup: ele.xp.netsuite.productgroup,
                width: ele.ShipWidth ? ele.ShipWidth.toString() : ele.ShipWidth ,
                thickness: ele.ShipHeight ? ele.ShipHeight.toString() : ele.ShipHeight,
                length: ele.ShipLength ? ele.ShipLength.toString() : '',
                description: ele.Description,
                locations: ele.xp.netsuite.locations,
                availabilityList: ele.xp.netsuite.availabilityList || [], //when rate is 0 set availablity to an empty list
                itemid: ele.xp.netsuite.id,
                active: ele.Active,
                alloy_grade: ele.xp.netsuite.custitem3,
                availability: ele.xp.netsuite.availability,
                itemGroup_name: ele.xp.netsuite.productgroup_name,
                tag: ele.xp.netsuite.tag,
                surface: ele.xp.netsuite.custitem4,
                gauge: ele.xp.netsuite.custitem_aam_steel_item_gauge,
                //checkAvailabilityWithID is a concatenation of the items' OrderCloud ID and items rate. -aam- was used as the delimeter because the OrderCloud ID can contain special characters
                checkAvailabilityWithID: ele.xp.netsuite.availabilityList.length > 0 ? `${ele.ID}` : ""
            })
        });

        //Get active items
        // ItemsArray = this.itemGroup_ids ? ItemsArray.filter((element) => this.itemGroup_ids.includes(element.itemGroup) && element.active === true) : ItemsArray;

        // const dataWithItemGroupsApplied = this.applyItemGroupsAndAvailability(ItemsArray,this.itemGroups);
        const processedItems = this.flattenUIDataAndSort(ItemsArray);
        //finally set UI related data
        this.inventorySearchData = {
            items: processedItems?.flattenedData,
            flattenedData: processedItems?.flattenedData,
            widths: this.createUniqueList(width),
            thicknesses: this.createUniqueList(thickness),
            lengths: sortedLength,
            itemGroups: [],
            locations: processedItems?.locations,
            gauge: toJS(this.createUniqueList(custitem_aam_steel_item_gauge)),
            surface: toJS(this.createUniqueList(surface))
        }
        this.deliveryRatesMatrix = deliveryRates //.map((deliveryRate) => deliveryRate?.xp)
    }
    setDeliveryRates(data) {
        this.deliveryRatesMatrix = data.deliveryRates;
    }
    setShippingRates(rates) {
        this.shippingRates = rates
    }
    setSelectedShipAddress(address) {
        this.selectedShipAddress = address
    }
    setUseAlternateAddress(value) {
        this.useAlternateAddress = value
    }

    flattenUIDataAndSort(items){
        let locations = []
        //flatten the items so that an item can be assigned several locations
        const flattenedData = items.flatMap(item => {
            return item.locations.map(location => {
                locations.push({ value: location.id, label: location.name })
                return { 
                    ...item, 
                    checkAvailabilityWithID: item.checkAvailabilityWithID + "-aam-" + location.id + '-aam-' + location.warehouse_location_id, //update the checkAvailabilityWithID field so that we get access to locationId and warehouse location Id
                    rate: (parseFloat(location.price)).toFixed(4), 
                    locationId: location.id, 
                    location: location.name,
                    warehouseLocationId: location.warehouse_location_id,
                    price: (parseFloat(location.price)).toFixed(4)
                };
            });
        });

        //sort data by availability
        flattenedData.sort((a, b) => {
            let nameA = a.availability; // ignore upper and lowercase
            let nameB = b.availability; // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        });

        //clean item data
        let cleanItems = flattenedData.filter((item) => item.price !== 0)
        cleanItems = cleanItems.filter((item) => item.location !== undefined)
        cleanItems = cleanItems.filter((item) => item.warehouseLocationId !== 'No warehouse assigned')
        const newLocations = locations.filter((location) => location.label !== undefined)
        
        return { flattenedData: cleanItems, locations: this.getUniqueObjects(newLocations) };
    }

    async getDynamicRates(data, page='no-page') {
        const { customerDefaultShippingAddress, customerShippingAddress } = authStore
        //get item delivery rates using customer default shipping address
        const uniqueWarehouseLocationIds = this.getUniqueWarehouseLocationIds(data);
        
        //Create custom data structure and send to rates endpoint to get appropriate rates
        let dynamicRatesPayload = { 
            shippingZone: customerDefaultShippingAddress?.shippingZone || "", 
            state: customerDefaultShippingAddress?.state || "", 
            locationid: uniqueWarehouseLocationIds 
        };

        if(this.selectedShipAddress && page==='checkout') {
            dynamicRatesPayload = { 
                shippingZone: this.selectedShipAddress?.shippingZone || "", 
                state: this.selectedShipAddress?.state || "", 
                locationid: uniqueWarehouseLocationIds 
            };
        }

        let dynamicRatesResponse = await this.getShippingRatesByWarehouseLocation(dynamicRatesPayload);

        //if default shipping address returns an empty list, try the alternate addresses
        if(dynamicRatesResponse?.length === 0) {
            //get all alternate addresses
            const alternateAddresses = customerShippingAddress.filter((address) => address?.defaultShipping === false);

            for (let i = 0; i < alternateAddresses.length; i++) {
                //prepare the payload
                dynamicRatesPayload = {
                    shippingZone: alternateAddresses[i]?.shippingZone || "", 
                    state: alternateAddresses[i]?.state || "", 
                    locationid: uniqueWarehouseLocationIds 
                }
                
                //make the request
                dynamicRatesResponse = await this.getShippingRatesByWarehouseLocation(dynamicRatesPayload);

                //if shipping rates are returned for an alternate address
                if(dynamicRatesResponse.length > 0) {

                    //set the alternate shipping address rates in the store
                    this.setShippingRates(dynamicRatesResponse);

                    //set alternate shipping address as selected ship address
                    this.setSelectedShipAddress(alternateAddresses[i]);

                    //Notify user we are using their alternate address
                    this.setUseAlternateAddress(true)

                    //return the response
                    return dynamicRatesResponse
                }
            }
        }
        //set the default shipping aadress rates in the store
        this.setShippingRates(dynamicRatesResponse);

        //Do not notify user we are using their alternate address
        this.setUseAlternateAddress(false)

        //return this when default shipping address is configured properly
        return dynamicRatesResponse;
    }

    getDynamicRatesLocal(data, page='no-page') {
        const { customerDefaultShippingAddress, customerShippingAddress } = authStore
        //get item delivery rates using customer default shipping address
        const uniqueWarehouseLocationIds = this.getUniqueWarehouseLocationIds(data);
        
        //Create custom data structure and send to rates endpoint to get appropriate rates
        let dynamicRatesPayload = { 
            shippingZone: customerDefaultShippingAddress?.shippingZone || "", 
            state: customerDefaultShippingAddress?.state || "", 
            locationid: uniqueWarehouseLocationIds 
        };
        if(this.selectedShipAddress && page==='checkout') {
            dynamicRatesPayload = { 
                shippingZone: this.selectedShipAddress?.shippingZone || "", 
                state: this.selectedShipAddress?.state || "", 
                locationid: uniqueWarehouseLocationIds 
            };
        }
        let dynamicRatesResponse = this.getShippingRatesByWarehouseLocationLocal(dynamicRatesPayload);

        //if default shipping address returns an empty list, try the alternate addresses
        if(dynamicRatesResponse?.length === 0) {
            //get all alternate addresses
            const alternateAddresses = customerShippingAddress.filter((address) => address?.defaultShipping === false);

            for (let i = 0; i < alternateAddresses.length; i++) {
                //prepare the payload
                dynamicRatesPayload = {
                    shippingZone: alternateAddresses[i]?.shippingZone || "", 
                    state: alternateAddresses[i]?.state || "", 
                    locationid: uniqueWarehouseLocationIds 
                }
                
                //make the request
                dynamicRatesResponse = this.getShippingRatesByWarehouseLocationLocal(dynamicRatesPayload);

                //if shipping rates are returned for an alternate address
                if(dynamicRatesResponse.length > 0) {

                    //set the alternate shipping address rates in the store
                    this.setShippingRates(dynamicRatesResponse);

                    //set alternate shipping address as selected ship address
                    this.setSelectedShipAddress(alternateAddresses[i]);

                    //Notify user we are using their alternate address
                    this.setUseAlternateAddress(true)

                    //return the response
                    return dynamicRatesResponse
                }
            }
        }

        //set the default shipping aadress rates in the store
        this.setShippingRates(dynamicRatesResponse);

        //Do not notify user we are using their alternate address
        this.setUseAlternateAddress(false)

        //return this when default shipping address is configured properly
        return dynamicRatesResponse;
    }

    //utility function for applying rates
    applyDynamicRates(items=[], rates) {
        items?.forEach((item) => {
            const itemRate = rates?.find((rate) => parseInt(item.warehouseLocationId) === parseInt(rate.lotLocationID))
            item.rate = (parseFloat(item.price) + (itemRate?.rate || 0)).toFixed(4)
        })

        return items;
    }
    
    setCustomerPriceLevel(data) {
        this.customerPriceLevel = data
    }

    getUniqueWarehouseLocationIds(items=[]) {
        //collect all warehouse location ids from items
        const ids = items.map((item) => item.warehouseLocationId);

        //create a unique collections of all the warehouse location ids. 
        const uniqueWarehouseLocationIds = Array.from(new Set(ids));

        return uniqueWarehouseLocationIds
    }

    getUniqueObjects(list) {
        // Use Set to store unique objects based on stringified representation
        const uniqueObjectsSet = new Set(list.map(obj => JSON.stringify(obj)));
        
        // Convert back to array of objects
        const uniqueObjectsArray = Array.from(uniqueObjectsSet).map(str => JSON.parse(str));

        //sort data by availability
        uniqueObjectsArray.sort((a, b) => {
            let nameA = a.label; // ignore upper and lowercase
            let nameB = b.label; // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            // names must be equal
            return 0;
        });
        
        return uniqueObjectsArray;
      }

    setItemGroups(data, itemType){
        //filter out the actulaGroups groups
        const actualGroups = data.groups.filter((actualGroup) => actualGroup?.custrecord58 !== null && actualGroup?.fullname?.includes(itemType))

        let groups = [];
        let tempGroups = [];
        actualGroups.forEach((element) =>  {
            if(tempGroups.includes((element.custrecord58 ? element.custrecord58 : element.name).toString())) {
                // do nothing
            } else {
                //trim product groups
                tempGroups.push((element.custrecord58 ? element.custrecord58 : element.name).toString())
                groups.push({value:(element.custrecord58 ? element.custrecord58 : element.name).toString(), label: element.custrecord58 ? element.custrecord58 : element.name })
            }
        });
        this.itemGroups = groups;
        // this.itemGroup_ids = data.item_group_ids;
    }

    setItemLocations(data) {
        this.itemLocations = data?.map((location) => ({value: location?.locationID, label: location?.addressName }))
    }

    getItemDifference(moreItemsList, lessItemsList) {
        return moreItemsList.filter(object1 => {
          return !lessItemsList.some(object2 => {
            return object1.item === object2.item;
          });
        });
    }

    setLotData(data, itemId){
        const { items } = data;
        let lots = []
        items.forEach((element) => {
            lots.push({ ...element.xp, ocLotID: element.ID, ocItemID: itemId, location: element.Address.AddressName, locationId: element.Address.xp.netsuite.id })
        })

        //this.setIsPendingRequest(false)
        this.lotData = lots.filter((element) => element.quantityonhand !== "0");
    }
    

    setCartItems(selectedLot,action) {
        //add selected lot item to exisitng cart items
        let selectedLots = [...this.cartItems, selectedLot];

        //set all cart items in the store variable
        this.cartItems = selectedLots;

        //set action that was triggered by user. i.e. remove or add
        this.action = action

        const { myCart } = cartStore

        const { currentCompanyID } = authStore;

        //filter out a company specific cart items
        let filteredCart = myCart ? myCart.filter((element) => element.companyID === currentCompanyID) : [];
        let updatedCartItems = []
        let myCartData = []
        if(filteredCart.length > 0){
            let storedCartItems = filteredCart[0].cartItems;
            updatedCartItems = [...storedCartItems, selectedLot];

            let nonCompanyCart = myCart ? myCart.filter((element) => element.companyID !== currentCompanyID) : [];
            myCartData = [...nonCompanyCart, { companyID: currentCompanyID, cartItems: updatedCartItems }]
        } else {
            if(myCart) {
                myCartData = [...myCart, { companyID: currentCompanyID, cartItems: this.cartItems }];
            } else {
                myCartData = [{ companyID: currentCompanyID, cartItems: this.cartItems }];
            }
        }
        //set my cart items
        cartStore.setMyCart(myCartData)
    }

    unSetCartItem(lotID, action){
        const { myCart } = cartStore
        const { currentCompanyID } = authStore;


        let items = this.cartItems.filter((element) => element.inventorynumber !== lotID);

        this.cartItems = items;
        this.action = action

        let nonCompanyCart = myCart ? myCart.filter((element) => element.companyID !== currentCompanyID) : [];
        let myCartData = [...nonCompanyCart,{ companyID: currentCompanyID, cartItems: items }];
        cartStore.setMyCart(myCartData)
    }

    setCartItemsFromStore() {
        const { myCart } = cartStore
        const { currentCompanyID } = authStore;

        let filteredCart = myCart ? myCart.filter((element) => element.companyID === currentCompanyID) : [];
        if(filteredCart.length > 0){
            filteredCart = filteredCart[0].cartItems
        }
        this.cartItems = filteredCart;

        return filteredCart;
    }

    clearCart(){
        const { myCart } = cartStore
        const { currentCompanyID } = authStore;
        let companyCart = myCart ? myCart.filter((element) => element.companyID === currentCompanyID) : [];

        this.cartItems = companyCart.length > 0 ? companyCart[0].cartItems : [];
    }

    slideCartIn(){
        const element = document.getElementById("purchase-order-board")
        element.style.right = "-2000px";
        element.style.transition = "0.5s ease-in-out";
    }

    slideViewIn(viewID){
        const element = document.getElementById(viewID)
        element.style.right = "20px";
        element.style.left = "20px";
        element.style.transition = "0.5s ease-in-out";
    }
    slideViewOut(viewID){
        const element = document.getElementById(viewID)
        element.style.right = "-1000px";
        element.style.left = ""
        element.style.transition = "0.5s ease-in-out";
    }

    setCustomerShippingAddressList(){
        //add custom shipping address to the list of addresses
        let addressList =[{ value: -2, text: "- Custom -"}];
        this.customerShippingAddress && this.customerShippingAddress.forEach((element) => {
            addressList.push({ value: element.internalId, text: element.label })
        })
        //set customer shipping addresses in the store variable
        this.customerShippingAddress = this.customerShippingAddress ? this.customerShippingAddress : [];
        this.addressList = addressList
    }
    toggleLotsView(state){
        this.showLotsView = state;
    }
    clearFilters(state){
        this.setClearInventoryFilters = state;
    }
    setCheckCompanySwitch(value){
        this.checkCompanySwitch = value;
    }
    setIsPendingRequest(value){
        this.isPendingRequest = value;
    }
    setAction(action) {
        this.action = action;
    }
    openCheckoutPage(value) {
        this.openCheckout = value;
    }

    forget() {
        this.hydrated = false;
        this.token = undefined;
        this.ocToken = undefined
        this.user = undefined;
        this.items = [];
        this.itemsFetched = [];
        this.item = undefined;
        this.itemLots = [];
        this.locations = [];
        this.lotStatus = [];
        this.itemsUpdatedAt = undefined;
        this.lotsUpdatedAt = undefined;
        this.lots = [];
        this.groups = [];
        this.filteredGroups = [];
        this.filteredGroups = [];
        this.categories = [];
        this.parts = [];
        this.filter = undefined;
        this.contact = undefined;
        this.dailyLME = [];
        this.filteredDailyLME = [];
        this.monthlyLME = [];
        this.filteredMonthlyLME = [];
        this.customerPriceLevel = undefined;
        this.itemLocations = undefined;
        this.shippingRates = undefined
        this.selectedShipAddress = undefined
        this.customerShippingAddress = []
        this.cartItems = []
        this.deliveryRatesMatrix = []
    }

    // Actions
    filterInventory({ search = '', categories = [], locations = [], availabilities = [], alloys = [], thickness = [], length = [], width = [], temper = [], lotWeight = { min: 0, max: 0} }) {
        this.filter = { search, categories, locations, availabilities, alloys, thickness, length, width, temper, lotWeight };

        this.filterGroupsByCategory(categories);

        if (locations && locations.length > 0) {
            this.filterGroupsByLocation(locations);
        }

        if (availabilities && availabilities.length > 0) {
            this.filterGroupsByAvailability(availabilities);
        }

        if (alloys && alloys.length > 0) {
            this.filterGroupsByAlloys(alloys);
        }

        if (thickness && thickness.length > 0) {
            this.filterGroupsByThickness(thickness);
        }

        if (length && length.length > 0) {
            this.filterGroupsByLength(length);
        }

        if (width && width.length > 0) {
            this.filterGroupsByWidth(width);
        }

        if (temper && temper.length > 0) {
            this.filterGroupsByTemper(temper);
        }

        if (lotWeight.min > 0 || lotWeight.max > 0) {
            this.filterGroupsByLotWeight(lotWeight);
        }

        if (search && search !== '') {
            this.filterGroupsBySearch(search);
        }

        this.filterCart();
    }

    filterGroupsByCategory(categories) {
        // Filter out items without quantityAvailable
        let items = this.items.filter(item => item.quantityAvailable).slice();

        // Create new objects (start fresh)
        items = JSON.parse(JSON.stringify(items));

        // Load groups
        let groups = this.groups.slice();

        // Create new objects (start fresh)
        groups = JSON.parse(JSON.stringify(groups));

        // Filter groups by category
        if (categories.length > 0) {
            groups = groups.filter((el) => { return categories.includes(el.parentId); });
        }

        let itemGroupedByGroup = _.groupBy(items, (i) => i.productGroup.internalId);

        // Group items
        groups.forEach((el) => {
            let itemsPerGroup = itemGroupedByGroup[el.internalId] || [];

            // Group locations
            let locationsPerGroup = [];
            let locations = [];
            itemsPerGroup.forEach((i) => {
                i.locations.forEach((l) => {
                    if ( ! locationsPerGroup.includes(l.locationId)) {
                        locationsPerGroup.push(l.locationId);
                        locations.push(l);
                    }
                });
            });

            locations.sort((a, b) => {
                let nameA = a.location; // ignore upper and lowercase
                let nameB = b.location; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });

            el['items'] = itemsPerGroup;
            el['locations'] = locations;
        });

        this.filteredGroups = groups;
    }

    filterGroupsByLocation(locations) {
        let groups = [];

        // Group items
        this.filteredGroups.forEach((el) => {
            let found = el.locations.find((l) => { return locations.includes(l.locationId); });
            if (found) {
                let groupItems = [];
                el.items.forEach((i) => {
                    let found = i.locations.find((l) => { return locations.includes(l.locationId); });
                    if (found) {
                        i['locations'] = i.locations.filter((l) => { return locations.includes(l.locationId); });
                        groupItems.push(i);
                    }
                });
                if (groupItems.length > 0) {
                    el['items'] = groupItems;
                    groups.push(el);
                }
            }
        });

        this.filteredGroups = groups;
    }

    filterGroupsByAvailability(availabilities) {
        // Load groups
        let filteredGroups = this.filteredGroups.slice();

        // Create new objects (start fresh)
        filteredGroups = JSON.parse(JSON.stringify(filteredGroups));

        let groups = [];

        let lots = this.lots;
        lots = JSON.parse(JSON.stringify(lots));

        let groupedLots = _.groupBy(lots, l => l.itemId + "--" + l.locationId);


        // Group items
        for(let el of filteredGroups) {
            let groupItems = [];

            el.items.forEach((i) => {
                let found = i.locations.some((location) => {
                    return groupedLots[i.itemId + "--" + location.locationId] && groupedLots[i.itemId + "--" + location.locationId].some(lot => availabilities.includes(lot.statusId));
                });

                if (found) {
                    let filteredLocations = i.locations.filter((location) => { return groupedLots[i.itemId + "--" + location.locationId] && groupedLots[i.itemId + "--" + location.locationId].some(lot => availabilities.includes(lot.statusId)); });
                    i['locations'] = filteredLocations;
                    groupItems.push(i);
                }
            });
            if (groupItems.length > 0) {
                el['items'] = groupItems;
                groups.push(el);
            }
        }

        this.filteredGroups = groups;

    }

    filterGroupsByAlloys(alloys) {
        let groups = [];

        // Group items
        this.filteredGroups.forEach((el) => {
            let groupItems = [];
            el.items.forEach((i) => {
                for (let k = 0; k < alloys.length; k++) {
                    let alloy = alloys[k];
                    let found = (i.itemId.indexOf(alloy) > -1) || (i.description.indexOf(alloy) > -1 || `${i.alloy}`.indexOf(alloy) > -1);
                    if (found) {
                        groupItems.push(i);
                        break;
                    }
                }
            });
            if (groupItems.length > 0) {
                el['items'] = groupItems;
                groups.push(el);
            }
        });

        this.filteredGroups = groups;
    }

    filterGroupsByThickness(thickness) {
        let groups = [];

        // Group items
        this.filteredGroups.forEach((el) => {
            let groupItems = [];
            el.items.forEach((i) => {
                for (let k = 0; k < thickness.length; k++) {
                    let thick = thickness[k];
                    let found = (i.itemId.indexOf(thick) > -1) || (i.description.indexOf(thick) > -1 || `${i.thickness}`.indexOf(thick) > -1);
                    if (found) {
                        groupItems.push(i);
                        break;
                    }
                }
            });
            if (groupItems.length > 0) {
                el['items'] = groupItems;
                groups.push(el);
            }
        });

        this.filteredGroups = groups;
    }

    filterGroupsByLength(length) {
        let groups = [];

        // Group items
        this.filteredGroups.forEach((el) => {
            let groupItems = [];
            el.items.forEach((i) => {
                for (let k = 0; k < length.length; k++) {
                    let l = length[k];
                    let found = (i.itemId.indexOf(l) > -1) || (i.description.indexOf(l) > -1 || `${i.length}`.indexOf(l) > -1);
                    if (found) {
                        groupItems.push(i);
                        break;
                    }
                }
            });
            if (groupItems.length > 0) {
                el['items'] = groupItems;
                groups.push(el);
            }
        });

        this.filteredGroups = groups;
    }

    filterGroupsByWidth(width) {
        let groups = [];

        // Group items
        this.filteredGroups.forEach((el) => {
            let groupItems = [];
            el.items.forEach((i) => {
                for (let k = 0; k < width.length; k++) {
                    let w = width[k];
                    let found = (i.itemId.indexOf(w) > -1) || (i.description.indexOf(w) > -1 || `${i.width}`.indexOf(w) > -1);
                    if (found) {
                        groupItems.push(i);
                        break;
                    }
                }
            });
            if (groupItems.length > 0) {
                el['items'] = groupItems;
                groups.push(el);
            }
        });

        this.filteredGroups = groups;
    }

    filterGroupsByTemper(temper) {
        let groups = [];

        // Group items
        this.filteredGroups.forEach((el) => {
            let groupItems = [];
            el.items.forEach((i) => {
                for (let k = 0; k < temper.length; k++) {
                    let w = temper[k];
                    let found = (i.itemId.indexOf(w) > -1) || (i.description.indexOf(w) > -1 || `${i.temper}`.indexOf(w) > -1);
                    if (found) {
                        groupItems.push(i);
                        break;
                    }
                }
            });
            if (groupItems.length > 0) {
                el['items'] = groupItems;
                groups.push(el);
            }
        });

        this.filteredGroups = groups;
    }

    filterGroupsByLotWeight(lotWeight) {
        let groups = [];

        let lots = this.lots;
        lots = JSON.parse(JSON.stringify(lots));

        let groupedLots = _.groupBy(lots, l => l.itemId + "--" + l.locationId);

        // Group items
        this.filteredGroups.forEach((group) => {
            let groupItems = [];
            group.items.forEach((i) => {
                let filteredLocations = [];
                i.locations.forEach((location) => {
                    let itemLotsbyLocation = (groupedLots[i.itemId + "--" + location.locationId] || []).filter((lot) => { return lot.available >= lotWeight.min && lot.available <= lotWeight.max ; });
                    if (itemLotsbyLocation.length > 0) {
                        location['lots'] = itemLotsbyLocation;
                        filteredLocations.push(location);
                    }
                });
                if (filteredLocations.length > 0) {
                    i['locations'] = filteredLocations;
                    groupItems.push(i);
                }
            });
            if (groupItems.length > 0) {
                group['items'] = groupItems;
                groups.push(group);
            }
        });

        this.filteredGroups = groups;
    }

    filterGroupsBySearch(searchInput) {
        let groups = [];

        let search = searchInput.toUpperCase();
        if (search.indexOf('0.0') > -1) {
            search = search.replace('0.0', '.0');
        }

        // Delimiters: ',', ' ', 'x', and 'X'
        let searchItems = search.split(/(?:,| |x|X)+/);

        // Group items
        this.filteredGroups.forEach((el) => {
            let groupItems = [];
            el.items.forEach((item) => {
                let matchesSize = searchItems.length;
                let matchesFound = 0;

                // Search on lots
                let itemLots = this.lots.filter((lot) => { return lot.itemId === item.itemId; });
                let lotsNumber = [];
                itemLots.forEach((lot) => {
                    lotsNumber.push(lot.lotId);
                });

                if (matchesFound >= matchesSize) {
                    groupItems.push(item);
                }
            });
            if (groupItems.length > 0) {
                el['items'] = groupItems;
                groups.push(el);
            }
        });

        this.filteredGroups = groups;
    }

    filterCart() {
        return waitFor(() => this.hydrated, () => this.pullCart());
    }

    pullCart() {
        if (cartStore.cartTotal > 0) {
            // Load items
            let filteredGroups = this.filteredGroups.slice();
            if (filteredGroups && filteredGroups.items && filteredGroups.items.length > 0) {
                filteredGroups.items.forEach((item) => {
                    let lotsSelected = cartStore.cartLots.filter((lot) => { return lot.itemInternalId === item.internalId; });
                    if (lotsSelected.length > 0) {
                        item['quantitySelected'] = lotsSelected.reduce((total, o) => { return total + o.quantity; }, 0);
                    } else {
                        item['quantitySelected'] = 0;
                    }
                });
            }
        }
    }

    fetchItems(refresh) {
        return waitFor(() => this.hydrated, () => this.pullItems(refresh));
    }

    pullItems(refresh) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/items?userId=${ this.user.userId }`)
            .then(action((response) => {
                this.itemsUpdatedAt = response.data['updatedAt'];
                let items = response.data['items'];
                let notAvailable = [];
                // Check for items usually in stock but not available
                this.allItems.forEach((item) => {
                    let found = items.find((el) => { return (el.internalId === item.internalId) || (el.itemId === item.itemId); });
                    if ( ! found) {
                        notAvailable.push(item);
                    }
                });
                this.items = items.concat(notAvailable);
                this.updateItemsLots(refresh);
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchItem(internalId, force) {

        if (!this.itemsFetched.includes(internalId) || force) {

            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get(`/api/inventory/item?internalId=${internalId}`)
                .then(action((item) => {
                    this.item = item.data;

                    // Update original inventory items
                    let existingItem = this.items.find((el) => { return el.internalId === internalId; });
                    if (existingItem) {
                        this.updateItemDetails(existingItem, item.data);
                        this.items = this.items.slice();
                    }

                    let group = this.filteredGroups.find((el) => { return el.internalId === existingItem.productGroup.internalId; });

                    // Update filtered inventory items
                    existingItem = group.items.find((el) => { return el.internalId === internalId; });
                    if (existingItem) {
                        this.updateItemDetails(existingItem, item.data);
                        this.filteredGroups = this.filteredGroups.slice();
                    }

                    if(!this.itemsFetched.includes(internalId)) {
                        this.itemsFetched.push(internalId);
                    }
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve();
            });
        }
    }

    updateItemDetails(existingItem, item) {
        existingItem['weight'] = item['weight'];
        existingItem['weightUnits'] = item['weightUnits'];
        existingItem['minimumQuantityUnits'] = item['minimumQuantityUnits'];
        existingItem['density'] = item['density'];
        existingItem.locations.forEach((el) => {
            let location = item.locations.find((o) => { return o.locationId === el.locationId; });
            el['quantityOnHand'] = location ? location['quantityOnHand'] : 0;
            el['quantityAvailable'] = location ? location['quantityAvailable'] : 0;
            el['quantityOnOrder'] = location ? location['quantityOnOrder'] : 0;
            el['quantityBackOrdered'] = location ? location['quantityBackOrdered'] : 0;
            el['quantityCommitted'] = location ? location['quantityCommitted'] : 0;
        });
        existingItem['pricing'] = item['pricing'];
    }

    fetchLocations() {
        return waitFor(() => this.hydrated, () => this.pullLocations());
    }

    pullLocations() {
        return new Promise((resolve) => {
            let items = this.items.slice();

            let locationIds = [];
            let locations = [];
            items.forEach((i) => {
                i.locations.forEach((l) => {
                    if ( ! locationIds.includes(l.locationId)) {
                        locationIds.push(l.locationId);
                        locations.push({ internalId: l.locationId, name: l.location });
                    }
                });
            });

            locations.sort((a, b) => {
                let nameA = a.name.toUpperCase(); // ignore upper and lowercase
                let nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });

            // Orlando - Landstar (on the top)
            let orlandoLandstar = locations.find((el) => { return el.name.toLowerCase().indexOf('landstar') > -1; });
            if (orlandoLandstar) {
                locations = locations.filter((el) => { return el.internalId !== orlandoLandstar.internalId; });
                locations.unshift(orlandoLandstar);
            }
            // Orlando - Bonded (on the top)
            let orlandoBonded = locations.find((el) => { return el.name.toLowerCase().indexOf('bonded') > -1; });
            if (orlandoBonded) {
                locations = locations.filter((el) => { return el.internalId !== orlandoBonded.internalId; });
                locations.unshift(orlandoBonded);
            }

            resolve(this.locations = locations);
        });
    }

    fetchLotStatus() {
        return new Promise((resolve) => {
            let data = [
                {
                    "internalId": "2",
                    "value": "In Stock",
                    "show": true
                },
                {
                    "internalId": "1",
                    "value": "On Water",
                    "show": true
                }
            ];
            resolve(this.lotStatus = data);
        });

        // axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;

        // return axios.get('/api/inventory/lotstatus')
        //     .then(action((lotStatus) => {
        //         let data = lotStatus.data;
        //         data.sort((a: any, b: any) => {
        //             let nameA = a.value; // ignore upper and lowercase
        //             let nameB = b.value; // ignore upper and lowercase
        //             if (nameA < nameB) {
        //                 return -1;
        //             }
        //             if (nameA > nameB) {
        //                 return 1;
        //             }
        //             // names must be equal
        //             return 0;
        //         });

        //         this.lotStatus = data;
        //     }));
    }

    fetchLots(refresh) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/lots?userId=${ this.user.userId }`)
            .then(action((lots) => {
                this.lotsUpdatedAt = lots.data.updatedAt;
                this.lots = lots.data.lots;
                this.updateItemsLots(refresh);
                mainStore.setLotsTimeout(true);
                mainStore.setLotsReady(true);
            }))
            .catch((error) => {
                mainStore.setLotsTimeout(true);
                mainStore.setLotsReady(true);
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    updateItemsLots(refresh) {
        // Create new objects (start fresh)
        let items = this.items;
        let lots = this.lots;

        let groupedLots = _.groupBy(lots, (lot) => lot.itemId);

        items.forEach((item) => {
            let lotsByItem = groupedLots[item.itemId] || [];

            let lotsGroupedByLocation = _.groupBy(lotsByItem, (lot) => lot.itemId);

            // Populate item -> lots (status and quantity)
            if (lotsByItem && lotsByItem.length > 0) {
                item.locations.forEach((location) => {
                    let lotStatus = [];
                    let lotsByLocation = lotsGroupedByLocation[location.locationId] || [];

                    if (lotsByLocation && lotsByLocation.length > 0) {
                        let status = lotsByLocation.map(item => item.statusId).filter((value, index, self) => self.indexOf(value) === index);
                        lotStatus = status;
                    }
                    location['lotStatus'] = lotStatus;
                });
            }

            // Populate cart quantities
            if (cartStore.cartTotal > 0) {
                let lotsSelected = cartStore.cartLots.filter((lot) => { return lot.itemInternalId === item.internalId; });
                if (lotsSelected.length > 0) {
                    item['quantitySelected'] = lotsSelected.reduce((total, o) => { return total + o.quantity; }, 0);
                } else {
                    item['quantitySelected'] = 0;
                }
            }
        });

        if (refresh) {
            // Update groups
            let emptyFilter = { search: '', categories: [], locations: [], availabilities: [], alloys: [], thickness:[], length: [], width: [], temper: [], lotWeight: { min: 0, max: 0} };
            this.filterInventory(emptyFilter);
        }
    }

    updateItemLots(internalId, lotWeight) {
        // Update original inventory items
        let existingItem = this.items.find((el) => { return `${el.internalId}` === `${internalId}`; });
        if (existingItem) {

            let lotsByItem = this.lots.filter((l) => { return l.itemId === existingItem.itemId; });
            if (lotsByItem && lotsByItem.length > 0) {

                existingItem.locations.forEach((location) => {
                    let lotsByLocation = lotsByItem.filter((l) => { return l.locationId === location.locationId; });
                    if (lotsByLocation && lotsByLocation.length > 0) {

                        // Check if lot is selected on cart
                        lotsByLocation.forEach((l) => {
                            if (cartStore.cartLots.find((lot) => { return lot.internalId === l.internalId; })) {
                                l['added'] = true;
                            } else {
                                l['added'] = false;
                            }
                        });

                        location['lots'] = lotsByLocation;
                    }
                });

                let lotsSelected = cartStore.cartLots.filter((lot) => { return lot.itemInternalId === existingItem.internalId; });
                if (lotsSelected.length > 0) {
                    existingItem['quantitySelected'] = lotsSelected.reduce((total, o) => { return total + o.quantity; }, 0);
                } else {
                    existingItem['quantitySelected'] = 0;
                }
            }

            this.items = this.items.slice();
        }

        let group = this.filteredGroups.find((el) => { return el.internalId === existingItem.productGroup.internalId; });

        // Update filtered inventory items

        existingItem = group && group.items ? group.items.find((el) => { return el.internalId === internalId; }) : null;
        if (existingItem) {

            let lotsByItem = this.lots.filter((l) => { return l.itemId === existingItem.itemId; });
            if (lotsByItem && lotsByItem.length > 0) {

                existingItem.locations.forEach((location) => {
                    let lotsByLocation = lotsByItem.filter((l) => { return l.locationId === location.locationId; });
                    if (lotsByLocation && lotsByLocation.length > 0) {

                        let filteredLots = [];

                        // Check if lot is selected on cart
                        lotsByLocation.forEach((l) => {
                            if (cartStore.cartLots.find((lot) => { return (lot.internalId === l.internalId) && (lot.itemInternalId === existingItem.internalId); })) {
                                l['added'] = true;
                            } else {
                                l['added'] = false;
                            }

                            if (this.filter && this.filter.availabilities && this.filter.availabilities.length > 0) {
                                if (this.filter.availabilities.includes(l.statusId)) {
                                    filteredLots.push(l);
                                }
                            }
                        });

                        if (this.filter && this.filter.availabilities && this.filter.availabilities.length > 0) {
                            location['lots'] = filteredLots;
                        } else {
                            location['lots'] = lotsByLocation;
                        }


                        // Check if lotWeight is active
                        if (lotWeight && (lotWeight.min > 0 || lotWeight.max > 0)) {
                            let lots = location['lots'];
                            lots = lots.filter((l) => { return l.available >= lotWeight.min && l.available <= lotWeight.max; });
                            let freshLots = JSON.parse(JSON.stringify(lots));
                            location['lots'] = freshLots;
                        }
                    }
                });

                let lotsSelected = cartStore.cartLots.filter((lot) => { return lot.itemInternalId === existingItem.internalId; });
                if (lotsSelected.length > 0) {
                    existingItem['quantitySelected'] = lotsSelected.reduce((total, o) => { return total + o.quantity; }, 0);
                } else {
                    existingItem['quantitySelected'] = 0;
                }
            }

            this.filteredGroups = this.filteredGroups.slice();
        }
    }

    fetchCategories() {
        return waitFor(() => this.hydrated, () => this.pullCategories());
    }

    pullCategories() {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get('/api/inventory/categories')
            .then(action((categories) => {
                categories.data.sort((a, b) => {
                    let nameA = a.order; // ignore upper and lowercase
                    let nameB = b.order; // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                });
                this.categories = categories.data;
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchCustomerParts() {
        return waitFor(() => this.hydrated, () => this.pullCustomerParts());
    }

    pullCustomerParts() {
        if (this.parts.length === 0) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get(`/api/inventory/parts?userId=${ this.user.userId }`)
                .then(action((parts) => {
                    this.parts = parts.data;
                    this.updateItemsClientId()
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve(window.setTimeout(undefined, 100));
            });
        }
    }

    updateItemsClientId() {
        // Create new objects (start fresh)
        let items = JSON.parse(JSON.stringify(this.items));

        // Populate parts
        items.forEach((item) => {
            let found = this.parts.find((p) => { return p.itemInternalId === `${item.internalId}`; });
            if (found) {
                item['partNumber'] = found.partNumber;
            }
        });

        // Update items
        this.items = items;

        // Update groups
        let emptyFilter = { search: '', categories: [], locations: [], availabilities: [], alloys: [], thickness:[], length: [], width: [], temper: [], lotWeight: { min: 0, max: 0} };
        this.filterInventory(emptyFilter);
    }

    fetchFile(fileId) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/file?id=${ fileId }`, {responseType: 'blob'})
            .then(action((file) => {
                let fileName = file.headers['content-disposition'].split("filename=\"")[1].slice(0, -1);
                FileDownload(file.data, fileName);
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    contactEmail(itemId, itemDescription) {
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post('/api/inventory/contact', `salesRepEmail=${ this.user.salesRepEmail }&userEmail=${ this.user.email }&itemId=${ itemId }&itemDescription=${ itemDescription }`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(action((email) => {
                this.contact = email.data;
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchDailyLME() {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/daily-lme`)
            .then(action((lme) => {
                let data = lme.data;
                data.sort((a, b) => {
                    let nameA = a.dateLme; // ignore upper and lowercase
                    let nameB = b.dateLme; // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                });
                this.dailyLME = data;
                this.filteredDailyLME = data;
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    filterDailyLME({ internalId = '' }) {
        // Loader start
        mainStore.setProgress({ loading: true });
        let allLme = JSON.parse(JSON.stringify(this.dailyLME));
        if (internalId !== '' && internalId !== undefined && internalId !== null) {
            let filtered = allLme.filter((el) => { return el.dateLme.indexOf(internalId) > -1; });
            this.filteredDailyLME = filtered;
        } else {
            this.filteredDailyLME = allLme;
        }
        mainStore.setProgress({ loading: false });
    }

    fetchLME() {
        return waitFor(() => this.hydrated, () => this.pullLME());
    }

    pullLME() {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/getLME`)
            .then(action((lme) => {
                let data = lme.data;
                data.sort((a, b) => {
                    let nameA = b.internalId; // ignore upper and lowercase
                    let nameB = a.internalId; // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                });
                this.monthlyLME = data;
                this.filteredMonthlyLME = data;
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    filterMonthlyLME({ internalId = '' }) {
        // Loader start
        mainStore.setProgress({ loading: true });
        let allLme = JSON.parse(JSON.stringify(this.monthlyLME));
        if (internalId !== '' && internalId !== undefined && internalId !== null) {
            let filtered = allLme.filter((el) => { return el.internalId.indexOf(internalId) > -1; });
            this.filteredMonthlyLME = filtered;
        } else {
            this.filteredMonthlyLME = allLme;
        }
        mainStore.setProgress({ loading: false });
    }


    // Generate rows of csv file
    processCSVData(objArray, header) {
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var csvRow = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line !=='') line += ','

                let text = array[i][index].replace(/,/g,'')
                line += text;
            }

            if(header.indexOf(line.split(",")[1]) === 0){
                csvRow += `${line.split(",")[0]},${line.split(",")[2]},,,\r\n`;
            }
            if(header.indexOf(line.split(",")[1]) === 1){
                csvRow += `${line.split(",")[0]},,${line.split(",")[2]},\r\n`;
            }
            if(header.indexOf(line.split(",")[1]) === 2){
                csvRow += `${line.split(",")[0]},,,${line.split(",")[2]}\r\n`;
            }
        }

        return csvRow;
    }

    // Generate rows for table
    processTableData(objArray, header) {
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let tableRow = []
        for (var i = 0; i < array.length; i++) {
            var line = '',obj={};
            for (var index in array[i]) {
                if (line !=='') line += ',';

                let text = array[i][index].replace(/,/g,'');
                line += text;
            }
            obj = {
                type: line.split(",")[3],
                description: line.split(",")[0],
                status1: header.indexOf(line.split(",")[1]) === 0 ? line.split(",")[2] : '',
                status2: header.indexOf(line.split(",")[1]) === 1 ? line.split(",")[2] : '',
                status3: header.indexOf(line.split(",")[1]) === 2 ? line.split(",")[2] : '',
                location: line.split(",")[1]
            };
            tableRow.push(obj);
        }

        return tableRow;
    }

    // Process Inventory Summary Data from api to fit in a table
    processSummaryResponse(data){
        let rows = "Row Labels";
        let locations=[];

        for(let i=0;i<Object.keys(data).length;i++){
            for(let j=0;j<data[Object.keys(data)[i]].length;j++){
                data[Object.keys(data)[i]][j].type = Object.keys(data)[i]
                locations.push(data[Object.keys(data)[i]][j].location);
            }
        }

        let uniqueLocations = new Set(locations);
        uniqueLocations.forEach((location) => {
            rows += "," + location
        })

        let headers = rows.split(","), tableData=[];
        headers.shift();
        let sortedHeaders = headers.sort();
        rows += '\r\n';

        for(let i=0;i<Object.keys(data).length;i++){

            rows += `${Object.keys(data)[i]},,,\r\n`;

            let t_data = this.processTableData(data[Object.keys(data)[i]],sortedHeaders);
            tableData.push(...t_data);
            rows += t_data + '\r\n';
        }
        return Object.freeze({ tableData, uniqueLocations:  sortedHeaders});
    }

    // Process Filtered Inventory Summary Data
    filteredSummaryResponse(filteredData){
        //var fileTitle = 'Inventory Summary';
        let rows = "Row Labels";
        let locations=[];
        for(let j=0;j<filteredData.length;j++){
            locations.push(filteredData[j].location);
        }

        let uniqueLocations = new Set(locations);
        uniqueLocations.forEach((location) => {
            rows += "," + location;
        })

        let headers = rows.split(",");
        headers.shift();
        let sortedHeaders = headers.sort();
        let tableData = this.processTableData(filteredData,sortedHeaders);

        return Object.freeze({ tableData, uniqueLocations: sortedHeaders });
    }

    // CSV Download function
    exportCSVFile(rows){
        // let rows = this.processSummaryResponse(data)
        var blob = new Blob([rows], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, "exportedFilenmae");
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "exportedFilenmae");
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    fetchInventorySummary() {
        return waitFor(() => this.hydrated, () => this.pullInventorySummary());
    }

    pullInventorySummary(script) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/inventorySummary?type=inventory_summary&channel=1`)
            .then(action((summary) => {
                for(let key in summary.data){
                    summary.data[key].forEach((item) => item.label = key) //Add each label to the items data
                }
                this.setInventorySummary(summary.data)
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    filterSummaryData(filterBy){
        let data = {};
        for(let key in filterBy){
            if(filterBy[key] === 'all') delete filterBy[key];
        }
        for(let summary in this.inventorySummary){
            data[summary] = this.inventorySummary[summary].filter((item) => {
                for(let key in filterBy){
                    if(item[key] === undefined || item[key] !== filterBy[key] || item[key] === 'all') return false;
                }
                return true
            })
        }
        this.setfilteredInventorySummary(data)
    }

    //fetch products
    async fetchProducts(product_type) {
        try {
            const response =  await api.get(`/api/inventory/inventorySearch?type=inventory_search&channel=1&filter=${product_type}`)
            if(typeof(response.data) === 'string' && response.data.includes('Access token is invalid or expired')){
                authStore.logout();
                window.location.reload()
                return;
            } else {
                if(response?.data?.items){
                    var startTime = performance.now()
                    this.setInventorySearchDataNew(response?.data)
                    var endTime = performance.now()
                    console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)
                }
            }
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        } finally {
            mainStore.setProgress({ loading: false });
        }

    }
    //get product groups
    async fetchInventoryProductGroups(itemType) {
        try {
            const response =  await api.get(`/api/inventory/inventorySummary?type=get_item_groups_helper&channel=1`)
            if(typeof(response.data) === 'string' && response.data.includes('Access token is invalid or expired')){
                authStore.logout();
                window.location.reload()
                return;
            } else {
                this.setItemGroups(response?.data, itemType)
            }
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        } finally {
            mainStore.setProgress({ loading: false });
        }
    }
    //get locations
    async fetchAvailableLocations() {
        try {
            const response =  await api.get(`/api/location/GetLocations`)
            if(typeof(response.data) === 'string' && response.data.includes('Access token is invalid or expired')){
                authStore.logout();
                window.location.reload()
                return;
            } else {
                this.setItemLocations(response?.data)
            }
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        } finally {
            mainStore.setProgress({ loading: false });
        }
    }

    async fetchLotsByItem(itemId, itemNSId, locationId) {
        try {
            const response =  await api.get(`/api/inventory/availableLotsByItem?type=inventory_search&itemId=${itemId}&itemNSId=${itemNSId}&locationId=${locationId}`)
            if(typeof(response.data) === 'string' && response.data.includes('Access token is invalid or expired')){
                authStore.logout();
                window.location.reload()
                return;
            } else {
                if(response.data.length < 1) {
                    //end sentry task
                    // prepare sentry transaction
                    sendSentryData(`Empty Lots For Item :: OrderCloud ItemId=${itemId} NetSuite ItemId=${itemNSId} Location ID ${locationId}`);
                }
                this.setLotData(response.data, itemId);
            }
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        } finally {
            mainStore.setProgress({ loading: false });
        }
    }

    async getShippingRatesByWarehouseLocation(payload) {
        try {

            const response =  await api.post('/api/shipping/GetShippingRate', payload)

            return response.data || []
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        } finally {
            mainStore.setProgress({ loading: false });
        }

    }

    async getDeliveryRates() {
        try {

            const response =  await api.get('/api/inventory/getDeliveryRates');

            this.setDeliveryRates(response.data || [])

            return response.data || []
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        } finally {
            mainStore.setProgress({ loading: false });
        }

    }

    getShippingRatesByWarehouseLocationLocal(payload) {
        const locationIDs = payload.locationid.map((e) => e.toString()) || [];
        const rates = this.deliveryRatesMatrix.map((rate) => {
            if(locationIDs.includes(rate?.warehouse_location_id.toString()) && (payload?.shippingZone === rate?.shipzone_name || payload?.state === rate?.shipzone_state)) {
                return {
                    lotLocationID: rate?.warehouse_location_id,
                    rate: rate?.rate_value,
                    shipZone: rate?.shipzone_name,
                    wareHouseLocation: rate?.warehouse_location_name
                }
            }
            return null
        })

        return rates.filter((rate) => rate !== null)
    }

    RemoveRecord(url) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        axios.defaults.headers.common['OrderCloudAuthorization'] = this.ocToken;

        return axios.get(`/api/inventory/removeRecord?url=${url}`)
            .then(action((response) => {
                const { data } = response
                if(typeof(data) === 'string' && data.data.includes('Access token is invalid or expired')){
                    authStore.logout();
                    window.location.reload()
                    return;
                } else {
                }
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
            });
    }
    fetchRecentItemFulfillments(currentCompanyID) {
        return waitFor(() => this.hydrated, () => this.pullRecentItemfulFilments(currentCompanyID));
    }
    pullRecentItemfulFilments(currentCompanyID) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        axios.defaults.headers.common['OrderCloudAuthorization'] = this.ocToken;

        return axios.get(`/api/inventory/getItemFulfilments?entityId=${currentCompanyID}`)
            .then(action((items) => {
                let data = items.data || [];
                data && data.forEach((element) => {
                    if(element.custbody_truck && element.custbody_stop){
                        element.bol = `${element.custbody_truck};${element.custbody_stop}`
                    } else {
                        element.bol = "";
                    }
                })

                this.recentItemFulfillments = data;

                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }
}

export const inventoryStore = new InventoryStore();
