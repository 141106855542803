import { observable, action, reaction, makeObservable } from 'mobx';
import clientPersist from 'client-persist';
import { makePersistable } from 'mobx-persist-store';



// Plugins
import axios from 'axios';

// Stores
import { inventoryStore } from './InventoryStore';
import { mainStore, waitFor } from './MainStore';
import { authStore } from './AuthStore';
import api from '../http';

const GOOGLE_MAPS_APIKEY = 'AIzaSyDLERcwUCxL87gg2_6RROa3qwMkguMsK-k';

// to use sessionStorage
clientPersist.setDriver(clientPersist.SESSIONSTORAGE);

class CartStore {
    // Observable state
    hydrated = false;
    token = undefined;
    ocToken = undefined;
    user = undefined;
    cart = { locations: [] };
    cartLots = [];
    cartTotal = 0;
    allLocations = [];
    salesOrder = undefined;
    orderMethods = [];
    shipMethods = [];
    truckRates = [];
    truckMinimumCapacity = 42000;
    truckOverCapacity = 5500;
    cartResponse = undefined;
    
    myCart = []

    constructor() {
        makeObservable(this, {
            hydrated: observable,
            token: observable,
            ocToken: observable,
            user: observable,
            cart: observable,
            cartLots: observable,
            cartTotal: observable,
            allLocations: observable,
            salesOrder: observable,
            orderMethods: observable,
            shipMethods: observable,
            truckRates: observable,
            truckMinimumCapacity: observable,
            truckOverCapacity: observable,
            cartResponse: observable,
            myCart: observable,

            setHydrated: action,
            setToken: action,
            setOCToken: action,
            setCartResponse: action,
            setCartInLocalStore: action,
            clearCompanyCart: action,
            setUser: action,
            forget: action,
            fetchCart: action,
            pullCart: action,
            saveLot: action,
            deleteLot: action,
            updateCartDelivery: action,
            updateLocationDelivery: action,
            updateLocationCosts: action,
            isValidCart: action,
            isTruckQuantityValid: action,
            isFullPickUp: action,
            findDuplicates: action,
            submitCart: action,
            fetchAllLocations: action,
            pullAllLocations: action,
            locationDistance: action,
            addressGeolocation: action,
            fetchOrderMethods: action,
            pullOrderMethods: action,
            fetchShipMethods: action,
            pullShipMethods: action,
            fetchTruckRates: action,
            pullTruckRates: action,
            saveCartLineItem: action,
            removeCartLineItem: action,
            setMyCart: action
            // getCartLineItem: action
            // setCartItems: action
        })
        let self = this;
        makePersistable(this, { name: 'cartStore', properties: ['token', 'ocToken', 'user', 'myCart'], storage: window.localStorage });

        reaction(
            () => this.cart,
            (cart) => {
                if (cart) {
                    if (cart && cart.locations) {
                        self.cartLots = [];
                        cart.locations.forEach((el) => {
                            self.cartLots = self.cartLots.concat(el.lots);
                        });
                        self.cartTotal = self.cartLots.length;
                    }
                }
            }
        );
    }
    
    setHydrated(hydrated) {
        this.hydrated = hydrated;
    }

    setToken(token) {
        this.token = token;
    }

    setOCToken(ocToken) {
        this.ocToken = ocToken;
    }
    setCartResponse(data) {
        this.cartResponse = data;
    }
    async setCartInLocalStore(data) {
        let myCartData = []

        // ===================================================================================
        // START CART ITEM AVAILABILITY CHECK
        // This block of code works but we are not using it in the UI yet. Client wants us to pause on it for a while
        // ===================================================================================
        // check from ordercloud if a lot in a customers cart is still available and also update its price
        const availableLotsWithCurrentPrices =   await this.setLotPurchaseAvailability(data);

        //retrieve all inventory numbers
        const inventoryNumbers = availableLotsWithCurrentPrices.map((availableLotsWithCurrentPrice) => availableLotsWithCurrentPrice.inventoryNumber)

        if(availableLotsWithCurrentPrices) {
            data.forEach((cartItem) => {
                /**
                 * Inventory Record IDs in OrderCloud changes whenever an Ineventory record is updated or patched
                 * Update cart items' xp values (ocItemID and ocLotID) in the app whenever cart items are fetched
                 */
                cartItem.xp.InventoryRecord.ocItemID = cartItem?.Product?.ID;
                cartItem.xp.InventoryRecord.ocLotID = cartItem?.InventoryRecordID;

                if(inventoryNumbers.includes(cartItem?.xp?.InventoryRecord?.inventorynumber)) {
                    //if cart item is available, flag it as true
                    cartItem.xp.InventoryRecord.isAvailable = true;

                    //get price
                    const priceData = availableLotsWithCurrentPrices.find((availableLotsWithCurrentPrice) => availableLotsWithCurrentPrice.inventoryNumber === cartItem?.xp?.InventoryRecord?.inventorynumber)
                    
                    //update price on a cart item
                    cartItem.xp.InventoryRecord.price = priceData?.currentPrice || 0;
                    
                } else {
                    //if cart item is NOT available, flag is as false
                    cartItem.xp.InventoryRecord.isAvailable = false;
                }
            })
        }
        // ===================================================================================
        // END CART ITEM AVAILABILITY CHECK
        // ===================================================================================

        //Get a list company Ids from cart line items
        let companyIDs = data.map((element) => element.xp.CompanyID);

        //Create a Set to get unique values
        companyIDs = new Set(companyIDs);

        companyIDs.forEach((element) => {
            let filteredCompanyCart = data.filter((ele) => element=== ele.xp.CompanyID)

            let cartItems = []
            filteredCompanyCart.forEach((item) => {
                cartItems.push({ ...item.xp.InventoryRecord, ...item, ocCartLineItemID: item.ID })
            })
            myCartData.push({ companyID: element, cartItems: cartItems })
        })

        //retrieve previous cart items
        const prevCartItems = inventoryStore.cartItems;
        //set my cart data
        this.setMyCart(myCartData)

        //refresh cartItems in inventory store with new item base price
        inventoryStore.setCartItemsFromStore()

        return { myCartData, prevCartItems };
    }

    async setLotPurchaseAvailability(cartItems = []) {
        //prepare payload 
        const payload = cartItems?.map((cartItem) => ({ "ItemID": cartItem?.ProductID, "LotID": cartItem?.InventoryRecordID }))
        
        //make the http request with payload
        const response = await api.post(`/api/cart/lotAvailableForPurchase`, { "CartLotItems": payload });
        return response?.data
    }
    clearCompanyCart(){
        const { currentCompanyID } = authStore;

        let nonCompanyCart = this.myCart ? this.myCart.filter((element) => element.companyID !== currentCompanyID) : [];
        let companyCart = this.myCart ? this.myCart.filter((element) => element.companyID === currentCompanyID) : [];
        companyCart[0].cartItems.forEach((element) => this.removeCartLineItem(element.ocCartLineItemID));
        inventoryStore.cartItems = []
        this.myCart =  nonCompanyCart;
    }

    //Remove this function. Price changes are checked on the server
    checkIfPricesHaveChange(prevCartItems=[], cartItems=[]) {
        /**
         * This method is used to check if prices on cart items have changed
         * 1. sum all the prices of the previous cart items
         * 2. sum all the prices of updated cart items
         * 3. compare both to see if there is a change
         * 
         * NB: this might fail if the sum of the new prices === the sume of the old prices
         * TODO :: Investigate how to efficiently determine base price changes and show notification to user.
         * Jira issue :: https://fjorge.atlassian.net/browse/AAM-1199
         */
        
        // 
        //sum all prices of previous cart items
        const sumPrevCartItems = prevCartItems.map((prevCartItem) => parseFloat(prevCartItem.price)).reduce((a,b) => a + b, 0);
        
        //sum all prices of updated cart items
        const sumCartItems = cartItems.map((cartItem) => parseFloat(cartItem.price)).reduce((a,b) => a + b, 0);

        //compare both to see if there is a change
        if(sumPrevCartItems !== sumCartItems) {
            mainStore.setSuccess(`Please note that rates for some of the metals you've selected have been revised.`)
        }

        cartItems.forEach((cartItem) => {
            const prevCartItem = prevCartItems.find((prevCartItem) => cartItem.price !== prevCartItem.price && cartItem.inventorynumber === prevCartItem.inventorynumber)
            if(prevCartItem) {
                cartItem.hasPriceChanged = true
                cartItem.oldPrice = prevCartItem?.rate
            }
        })

        return cartItems;
    }

    setMyCart(cartData) {
        this.myCart = cartData
    }

    setUser(user) {
        this.user = user;
    }

    forget() {
        this.hydrated = false;
        this.token = undefined;
        this.ocToken = undefined
        this.user = undefined;
        this.cart = { locations: [] };
        this.cartLots = [];
        this.cartTotal = 0;
        this.allLocations = [];
        this.salesOrder = undefined;
        this.orderMethods = [];
        this.shipMethods = [];
        this.truckRates = [];

        this.cartItems = []
        this.myCart = []
    }

    fetchCart() {
        return waitFor(() => this.hydrated, () => this.pullCart());
    }

    pullCart() {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/cart/lots?userId=${ this.user.userId }`)
            .then(action((cart) => {
                this.cart = (cart.data && cart.data != null) ? cart.data : { locations: [] };
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    saveLot(lotInternalId, locationId, itemInternalId, itemId, itemPrice, itemCostUnits, quantity) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post('/api/cart/lot', `userId=${ this.user.userId }&internalId=${ lotInternalId }&locationId=${ locationId }&itemInternalId=${ itemInternalId }&itemId=${ itemId }&itemPrice=${ itemPrice }&itemCostUnits=${ itemCostUnits }&quantity=${ quantity }`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(action((cart) => {
                this.cart = cart.data;
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    deleteLot(lotInternalId, locationId) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.delete('/api/cart/lot', { data: {userId: this.user.userId, internalId: lotInternalId, locationId } })
            .then(action((cart) => {
                this.cart = cart.data;
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    updateCartDelivery(orderMethod, shipMethod, shipAddress, poNumber) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.put('/api/cart/delivery', `userId=${ this.user.userId }&orderMethod=${ orderMethod }&shipMethod=${ shipMethod }&shipAddress=${ shipAddress }&poNumber=${ poNumber }`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(action((cart) => {
                this.cart = cart.data;
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    updateLocationDelivery(orderMethod, shipMethod, shipAddress, poNumber, dueDate, comments, locationId) {
        this.locationDistance(orderMethod, locationId, shipAddress, (distance) => {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.put('/api/cart/location', `userId=${ this.user.userId }&orderMethod=${ orderMethod }&shipMethod=${ shipMethod }&shipAddress=${ shipAddress }&poNumber=${ poNumber }&dueDate=${ dueDate }&comments=${ comments }&id=${ locationId }&distance=${ distance }`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
                .then(action((cart) => {
                    this.cart = cart.data;
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        });
    }

    updateLocationCosts(trucksNeeded, shippingCost, itemsTotalCost, grandTotalCost, locationId) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.put('/api/cart/location', `userId=${ this.user.userId }&trucksNeeded=${ trucksNeeded }&shippingCost=${ shippingCost }&itemsTotalCost=${ itemsTotalCost }&grandTotalCost=${ grandTotalCost }&id=${ locationId }`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(action((cart) => {
                this.cart = cart.data;
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    isValidCart() {
        let valid = true;
        if (this.cartTotal === 0) {
            mainStore.setError('Error: Cart is empty.');
            valid = false;
        } else if (this.cart.locations.length === 0) {
            mainStore.setError('Error: Cart is empty.');
            valid = false;
        } else {
            let poNumbers = [];
            for (let i = 0; i < this.cart.locations.length; i++) {
                let l = this.cart.locations[i];
                poNumbers.push(l.poNumber);
                let location = inventoryStore.locations.find((el) => { return el.internalId === l.locationId; });
                if (l.dueDate === undefined || l.dueDate === null) {
                    mainStore.setError(`Error: Due Date is required for ${ location.name } Warehouse.`);
                    valid = false;
                    break;
                }
                if (l.poNumber === undefined || l.poNumber === null) {
                    mainStore.setError(`Error: Purchase Order # is required for ${ location.name } Warehouse.`);
                    valid = false;
                    break;
                }
                if (l.shipMethod === '-1') {
                    mainStore.setError(`Error: Shipping Method is required for ${ location.name } Warehouse.`);
                    valid = false;
                    break;
                }
                if (l.shipAddress === '-1') {
                    mainStore.setError(`Error: Delivery Location is required for ${ location.name } Warehouse.`);
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }

    isTruckQuantityValid() {
        let valid = true;
        let cart = JSON.parse(JSON.stringify(this.cart));
        for (let i = 0; i < cart.locations.length; i++) {
            let l = cart.locations[i];
            const quantitySelected = l.lots.reduce((total, o) => { return total + o.quantity; }, 0);
            if (quantitySelected < this.truckMinimumCapacity) {
                valid = false;
                break;
            }
        };
        return valid;
    }

    isFullPickUp() {
        let pickUp = true;
        let cart = JSON.parse(JSON.stringify(this.cart));
        for (let i = 0; i < cart.locations.length; i++) {
            let l = cart.locations[i];
            if (l.orderMethod !== '1') {
                pickUp = false;
                break;
            }
        };
        return pickUp;
    }

    findDuplicates(data) {
        let result = [];
        data.forEach(function(element, index) {
          // Find if there is a duplicate or not
          if (data.indexOf(element, index + 1) > -1) {
            // Find if the element is already in the result array or not
            if (result.indexOf(element) === -1) {
              result.push(element);
            }
          }
        });
        return result;
    }

    submitCart() {
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post('/api/cart/order', `userId=${ this.user.userId }&entityId=${ this.user.entityId }&ipAddress=${ this.user.ipAddress }`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(action((cart) => {
                this.cart = cart.data;
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchAllLocations() {
        return waitFor(() => this.hydrated, () => this.pullAllLocations());
    }

    pullAllLocations() {
        if (this.allLocations.length === 0) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get('/api/inventory/locations')
                .then(action((allLocations) => {
                    this.allLocations = allLocations.data;
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve(window.setTimeout(undefined, 100));
            });
        }
    }

    locationDistance(orderMethod, locationId, shipAddress, callback) {
        // Pick-up
        if (orderMethod === '1' || shipAddress === '-1') {
            return callback(0);
        }
        if (shipAddress === null) {
            return callback(null);
        }
        let origin = this.allLocations.find((el) => { return el.internalId === locationId; });
        let origins = [origin.addrText];
        let destination = this.user.addressList.find((el) => { return el.internalId === shipAddress; });
        let destinations = [destination.addrText];
        const proxyurl = 'https://concepta-cors-anywhere.herokuapp.com/';
        const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${ encodeURI(origin.addrText) }&destinations=${ encodeURI(destination.addrText) }&mode=driving&units=imperial&language=en&avoid=&key=${ GOOGLE_MAPS_APIKEY }`;
        fetch(proxyurl + url)
            .then(response => response.json())
            .then(distances => {
                if (distances.status === 'OK') {
                    for (var i=0; i < origins.length; i++) {
                        for (var j = 0; j < destinations.length; j++) {
                            var origin = distances.origin_addresses[i];
                            if (distances.rows[0].elements[j].status === 'OK') {
                                var distance = distances.rows[i].elements[j].distance.text;
                                return callback(distance.split(' ')[0]);
                            } else {
                                mainStore.setError('Selected location is not reachable from ' + origin + '.');
                                return callback(0);
                            }
                        }
                    }
                } else {
                    mainStore.setError('Cannot access google maps api.');
                    return callback(0);
                }
            })
            .catch(() => mainStore.setError('Cannot access google maps api.'));
    }

    addressGeolocation(address, callback) {
        const proxyurl = 'https://concepta-cors-anywhere.herokuapp.com/';
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${ encodeURI(address) }&sensor=false&key=${ GOOGLE_MAPS_APIKEY }`;
        fetch(proxyurl + url)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK' && data.results.length > 0) {
                    callback(data.results[0].geometry.location);
                } else {
                    mainStore.setError('Cannot access google maps api.');
                    return callback(0);
                }
            })
            .catch(() => mainStore.setError('Cannot access google maps api.'));
    }

    fetchOrderMethods() {
        return waitFor(() => this.hydrated, () => this.pullOrderMethods());
    }

    pullOrderMethods() {
        if (this.orderMethods.length === 0) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get(`/api/cart/order-methods`)
                .then(action((orderMethods) => {
                    let data = orderMethods.data.filter((el) => { return (el.name.toLowerCase().indexOf('pick up') > -1) || (el.name.toLowerCase().indexOf('delivery') > -1); });
                    this.orderMethods = data;
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve(window.setTimeout(undefined, 100));
            });
        }
    }

    fetchShipMethods() {
        return waitFor(() => this.hydrated, () => this.pullShipMethods());
    }

    pullShipMethods() {
        return new Promise((resolve) => {
            let data = [
                {internalId: "3023", name: "Flatbed Only"},
                {internalId: "5392", name: "Flatbed or Van"},
                {internalId: "3020", name: "Pick-Up"}
            ];
            resolve(this.shipMethods = data);
        });
    }

    fetchTruckRates() {
        return waitFor(() => this.hydrated, () => this.pullTruckRates());
    }

    pullTruckRates() {
        if (this.truckRates.length === 0) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get(`/api/cart/trucks`)
                .then(action((truckRates) => {
                    this.truckRates = truckRates.data;
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve(window.setTimeout(undefined, 100));
            });
        }
    }


    //Order Cloud Specifics
    async saveCartLineItem(payload) {
        try {
            const resposne = await api.post(`/api/cart/saveCartLineItem`, payload)
            this.setCartResponse(resposne?.data)

        } catch (error) {
            
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        }
    }

    removeCartLineItem(cartLineItemID) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        axios.defaults.headers.common['OrderCloudAuthorization'] = this.ocToken;

        return axios.get(`/api/cart/removeCartLineItem?cartLineItemId=${cartLineItemID}`)
            .then(action((response) => {
                const { data } = response
                if(typeof(data) === 'string' && data.data.includes('Access token is invalid or expired')){
                    authStore.logout();
                    window.location.reload()
                    return;
                } else {
                    this.setCartResponse(data)
                }
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
            });
    }

    getCartLineItems() {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        axios.defaults.headers.common['OrderCloudAuthorization'] = this.ocToken;

        return axios.get(`/api/cart/getCartLineItems`)
            .then(action((response) => {
                const { data } = response
                if(typeof(data) === 'string' && data.data.includes('Access token is invalid or expired')){
                    authStore.logout();
                    window.location.reload()
                    return;
                } else {
                    this.setCartInLocalStore(data)
                }
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
            });
    }

    async getCartItems() {
        try {
            const response = await api.get(`/api/cart/getCartLineItems`);
            return this.setCartInLocalStore(response?.data);
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        }
    }
}

export const cartStore = new CartStore();